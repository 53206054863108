import React from "react";
import { ScitaraLogo } from "../../icons";
import AccountDropdown from "./AccountDropdown";
import styles from "./Header.module.scss";
import { Link } from "react-router-dom";
import { ROOT } from "../../views/routes";
import { useCurrentUserContext } from "../../contexts/current-user/Provider";
import { ScitaraHelpButton } from "../ScitaraHelp";
import { isInIframe } from "@dlx/utils/index";

export function Header({ children }) {
  const { currentUser } = useCurrentUserContext();
  return (
    <div className={styles.header}>
      <div className={styles.logoContainer}>
        <ScitaraLogoContent />
      </div>
      {currentUser && (
        <div className={styles.controlsContainer}>
          <div className={styles.controls}>{children}</div>
          <AccountDropdown />
        </div>
      )}
    </div>
  );
}

export const AppHeader = () => {
  return (
    <Header>
      <div className={styles.HelpLinks}>
        <ScitaraHelpButton />
      </div>
    </Header>
  );
};

export default AppHeader;

export function ScitaraLogoContent() {
  if (isInIframe()) {
    return <ScitaraLogo className={styles.headerLogo} />;
  }
  return (
    <Link to={ROOT}>
      <ScitaraLogo className={styles.headerLogo} />
    </Link>
  );
}
