import React, { useState, useEffect, useMemo } from "react";
import { useDispatch } from "react-redux";
import Button from "@mui/material/Button";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  executeExpression,
  getExpressionValue,
} from "@dlx/components/ExpressionEditor";

import { alertError } from "../../state/alerts/actions";
import useLeaseConnection from "../../state/connections/lease/useLeaseConnection";
import ConnectionInterfaceTable from "../Connection/Interface/ConnectionInterfaceTable";
import styles from "./StepContent.module.scss";

export function UserReadingInterface({
  submitUserInput,
  connection,
  connector,
  options = {},
  submitted,
  working,
  stepNum,
  getStepInputs,
  labels,
}) {
  const [userData, setUserData] = useState({});
  const dispatch = useDispatch();
  const [loadingStepData, setLoadingStepData] = useState(false);
  const [mappedOptions, setMappedOptions] = useState(null);
  const [readingInProgress, setReadingInProgress] = useState(false);
  const { abandonLease } = useLeaseConnection();
  const [connectionId, setConnectionId] = useState(null);

  const updateInterfaceData = (update) => {
    setUserData({ ...userData, ...update });
  };

  useEffect(() => {
    let mounted = true;
    const mapOptions = async (opt, ctx) => {
      try {
        const mappedOpts = {};
        for (const key in opt) {
          const response = await executeExpression(opt[key], ctx);
          mappedOpts[key] = response.data;
        }
        setMappedOptions(mappedOpts);
        setLoadingStepData(false);
      } catch (error) {
        dispatch(alertError("An error occurred retrieving execution step data"));
        setMappedOptions({});
      }
    };

    getStepInputs(stepNum)?.then((inputs) => {
      if (
        mounted &&
        inputs.triggeredAt &&
        (stepNum === 0 ||
          (Array.isArray(inputs.steps) && inputs.steps.length === stepNum)) &&
        !mappedOptions
      ) {
        getExpressionValue(connection, inputs).then((data) => {
          setConnectionId(data);
        });
        mapOptions(options, inputs);
      }
    });
    return () => {
      mounted = false;
    };
  }, [getStepInputs, mappedOptions, stepNum, dispatch, options, connection]);

  const handleOnClick = () => {
    abandonLease();
    submitUserInput(userData);
  };

  const disableSubmit = useMemo(
    () =>
      working ||
      submitted ||
      (Array.isArray(userData.accepted) && !userData.accepted.length) ||
      !userData.accepted ||
      (mappedOptions?.recordImage === "required" && !userData.filePackage) ||
      (mappedOptions?.recordMemo === "required" && !userData.memo) ||
      readingInProgress,
    [userData, submitted, mappedOptions, working, readingInProgress]
  );

  if (loadingStepData) {
    return (
      <div className={styles.loader}>
        <FontAwesomeIcon icon="spinner" spin />
      </div>
    );
  }

  return (
    <div className={styles.jsonFormContainer}>
      <ConnectionInterfaceTable
        options={mappedOptions || {}}
        interfaceData={userData}
        updateInterfaceData={updateInterfaceData}
        connectionId={connectionId}
        connectorId={connector}
        setReadingInProgress={setReadingInProgress}
        labels={labels}
      />
      <div className={styles.buttonContainer}>
        <div> </div>
        <div className={styles.buttonHolder}>
          <Button
            variant="contained"
            color="primary"
            className={styles.submit}
            onClick={handleOnClick}
            disabled={disableSubmit}
            startIcon={working && <FontAwesomeIcon icon="spinner" spin />}
          >
            {submitted ? "Submitted" : "Submit"}
          </Button>
        </div>
      </div>
    </div>
  );
}

export default UserReadingInterface;
