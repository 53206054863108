import React from "react";
import { makeStyles } from "tss-react/mui";
import Tooltip from "@mui/material/Tooltip";
import variables from "../../assets/styles/variables.scss";

const DLXTooltip = ({
  children,
  placement = "bottom-start",
  size = "1em",
  text = "",
  className = "",
  ...props
}) => {
  const { classes, cx } = useStyles({ size });
  return (
    <Tooltip
      classes={{
        tooltip: cx(classes.customTooltip, className),
      }}
      title={text}
      placement={placement}
      {...props}
    >
      {children}
    </Tooltip>
  );
};

const useStyles = makeStyles()({
  customTooltip: {
    backgroundColor: variables.lightgraybackground,
    border: `1px solid ${variables.lightgrayborder}`,
    boxShadow: variables.boxshadow,
    color: variables.darkgraytext,
    fontSize: (props) => props.size,
    zIndex: 10000,
  },
});

export default DLXTooltip;
