import * as Yup from "yup";
import { attributes, description, name, id, labelName, labelValue } from "./common";

const trigger = Yup.mixed();

export const state = Yup.string().oneOf(["enabled", "disabled"]).default("disabled");
export const orchestrationVersion = Yup.number().oneOf([1, 2]).default(2);
export const label = Yup.object({
  name: labelName.trim(),
  value: labelValue.trim(),
});

export const updateOrchestrationSchema = Yup.object({
  name: name.trim().required(),
  labels: Yup.array(),
  description: description.trim(),
  attributes: attributes,
  state: state,
  steps: Yup.array(),
  trigger: trigger,
  id,
  orchestrationVersion,
  labelExpressions: Yup.array(label),
}).noUnknown();

export const createOrchestrationSchema = Yup.object({
  name: name.trim().required(),
  labels: Yup.array().default([]),
  description: description.trim(),
  attributes: attributes.default({}),
  state: state.required(),
  steps: Yup.array().default([]).nullable(),
  trigger: trigger.default({ kind: "event" }).required(),
  labelExpressions: Yup.array(label).default([]),
  orchestrationVersion: orchestrationVersion.required(),
}).noUnknown();
