import React from "react";
import LabelChip, { LABEL_CHIP_VARIANTS } from "@dlx/atoms/LabelChip";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { FlaskIcon } from "../../../../icons";
import { formatDate } from "@dlx/utils/index";
import styles from "../ConnectionInterfaceTable.module.scss";

const formattedDate = (timestamp) => {
  try {
    return formatDate(new Date(timestamp));
  } catch (error) {
    return "Invalid date";
  }
};
const ReadingChip = ({ reading, index, rejectReading, rejected }) => {
  const { status, payload, recordedAt } = reading;

  const invalid = !status.valid;
  const variant = rejected
    ? LABEL_CHIP_VARIANTS.SOLID.SECONDARY
    : (invalid && LABEL_CHIP_VARIANTS.SOLID.ERROR) ||
      LABEL_CHIP_VARIANTS.SOLID.PRIMARY;

  const ReadingElement = ({ rdg, attr }) => (
    <div className={styles.readingKey}>
      <b>{rdg.label || attr}:</b>
      {` ${rdg.value} ${rdg?.units || ""}`}
    </div>
  );

  const tooltip = (
    <div style={{ padding: "5px" }}>
      <div>
        <b>Recorded at:</b>
        {` ${recordedAt ? formattedDate(recordedAt) : "No timestamp"}`}
      </div>
      <div>
        <b>Message:</b>
        {` ${status?.message}`}
      </div>
      {Object.entries(payload).map(([attr, rdg], i) => (
        <ReadingElement key={`element-${rdg.label}-${i}`} rdg={rdg} attr={attr} />
      ))}
    </div>
  );

  return (
    <LabelChip
      key={index}
      label={`${formattedDate(recordedAt)}${rejected ? " (REJECTED)" : ""}`}
      leftIcon={<FlaskIcon className="mh1" />}
      rightIcon={
        rejectReading && <FontAwesomeIcon icon="times-circle" className="ml1" />
      }
      error={!status.valid}
      variant={variant}
      tooltip={tooltip}
      onRightIconClick={() => rejectReading(reading)}
      dataTestId={`dtid_reading_${index}`}
      className="mr2 mv1"
    />
  );
};

export default ReadingChip;
