import React from "react";

import { makeStyles } from "tss-react/mui";
import Tooltip from "@mui/material/Tooltip";
import { colors } from "@dlx/assets/styles";

const TooltipWrapper = ({ children, text = "" }) => {
  const { classes } = useStyles();
  return (
    <Tooltip
      arrow
      enterDelay={500}
      TransitionProps={{ timeout: 0 }}
      classes={{
        tooltip: classes.customTooltip,
        arrow: classes.arrow,
      }}
      title={text}
      placement="top-start"
    >
      {children}
    </Tooltip>
  );
};

const useStyles = makeStyles()({
  customTooltip: {
    fontSize: "1rem",
    backgroundColor: colors.bgSecondary,
    border: `1px solid ${colors.bgSecondary}`,
    color: colors.textDefault,
  },
  arrow: {
    color: colors.bgSecondary,
  },
});

export default TooltipWrapper;
