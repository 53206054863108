import { formatDate } from "../../utils";
import { addYears } from "date-fns";

export const triggers = {
  timer: {
    name: "Timer trigger",
    description:
      "The orchestration will be triggered based on the schedule configured.",
    initialState: {
      interval: 1,
      units: "minute",
      start: formatDate(new Date(Date.now()), "yyyy-MM-dd'T'HH:mm:ss.SSSxxx"),
      kind: "timer",
      until: formatDate(
        addYears(new Date(Date.now()), 1),
        "yyyy-MM-dd'T'HH:mm:ss.SSSxxx"
      ),
    },
  },
  event: {
    name: "Connection event",
    description:
      "The orchestration will be triggered when the specified event occurs on the selected connection.",
    initialState: {
      kind: "event",
    },
  },
  manual: {
    name: "User triggered",
    description: `The orchestration will be triggered when either a user manually triggers it from the orchestration view, or
      the unique url for this orchestration is invoked. Note that the trigger payload can be created using url-encoded
       parameters.`,
    initialState: {
      kind: "manual",
    },
  },
  interactive: {
    name: "IO completed",
    initialState: {
      kind: "interactive",
    },
    deprecatedFeature: true,
  },
};
