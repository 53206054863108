import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import LabelChip, { LABEL_CHIP_VARIANTS } from "@dlx/atoms/LabelChip";
import ConnectionSelector from "../../ConnectionSelector";
import styles from "../ConnectionInterfaceTable.module.scss";
import { PlugIcon } from "../../../../icons";

const Connection = (props) => {
  return props?.connection?.status ? (
    <ConnectionInfo {...props} />
  ) : (
    <SelectConnection {...props} />
  );
};

const SelectConnection = ({ connectorId, setConnectionId, labels }) => {
  return (
    <tr>
      <td>Connection</td>
      <td>
        <span className={styles.info}>
          <ConnectionSelector
            connectorId={connectorId}
            setConnectionId={setConnectionId}
            labels={labels}
          />
        </span>
      </td>
    </tr>
  );
};

const ConnectionInfo = ({ isOnline, connection, setConnectionId }) => {
  return (
    <tr>
      <td>Connection</td>
      <td>
        <LabelChip
          label={`${connection.name}${isOnline ? "" : " (offline)"}`}
          leftIcon={<PlugIcon className="ml1" />}
          rightIcon={<FontAwesomeIcon icon="times-circle" />}
          onRightIconClick={() => setConnectionId(null)}
          variant={
            isOnline
              ? LABEL_CHIP_VARIANTS.SOLID.PRIMARY
              : LABEL_CHIP_VARIANTS.SOLID.SECONDARY
          }
          dataTestId={`dtid_reading_connection_${connection.name}`}
        />
      </td>
    </tr>
  );
};

export default Connection;
