import * as Yup from "yup";

export const noWhiteSpacesRegex = new RegExp(/^[^\s]+(\s+[^\s]+)*$/);
export const attributes = Yup.mixed();
export const description = Yup.string()
  .matches(
    noWhiteSpacesRegex,
    "description should not contain white spaces at the beginning and ending"
  )
  .min(0)
  .max(1000, "max allowed characters is 1000");
export const labelName = Yup.string()
  .matches(
    noWhiteSpacesRegex,
    "labelName should not contain white spaces at the beginning and ending"
  )
  .min(1)
  .max(100, "max allowed characters is 100");
export const labelValue = Yup.string().matches(
  noWhiteSpacesRegex,
  "labelValue should not contain white spaces at the beginning and ending"
);

export const name = Yup.string()
  .matches(
    noWhiteSpacesRegex,
    "name should not contain white spaces at the beginning and ending"
  )
  .min(2)
  .max(100);
export const id = Yup.string().matches(/^[0-9a-fA-F]{24}$/);
export const roles = Yup.array();
export const email = Yup.string().email();
export const password = Yup.string()
  .min(8)
  .max(99)
  .matches(/[a-z]/, "password must contain at least one lowercase letter")
  .matches(/[A-Z]/, "password must contain at least one uppercase letter")
  .matches(/\d/, "password must contain a number")
  .matches(
    /[~`!#$%^&@*+=\-[\].';,/{}|\\":<>?]/g,
    `password must contain at least one special character (such as !, %, @, or #)`
  );

export const grant = Yup.object().shape({
  access: Yup.string(),
  resourceGroups: Yup.array().of(Yup.string()),
  userGroups: Yup.array().of(Yup.string()),
});

export const grants = Yup.array().of(grant);
