import React, { useMemo } from "react";
import { uiTypeIs, rankWith } from "@jsonforms/core";
import { withJsonFormsCellProps, withJsonFormsControlProps } from "@jsonforms/react";
import MuiSelectorControl from "./MuiSelectorControl";

const UserSelectorTester = rankWith(
  103, //increase rank as needed
  uiTypeIs("DlxUserSelector")
);

const UserSelector = (props) => {
  const newProps = useMemo(() => {
    props.uischema.options = {
      ...props.uischema.options,
      labelRequiresLookup: true,
    };
    if (props.uischema.options.showGroups) {
      props.uischema.options.selections = [
        {
          label: "Users",
          lookup: { route: "/lookups/users" },
          value: "users",
        },
        {
          label: "User groups",
          lookup: { route: "/lookups/user-groups" },
          value: "user-groups",
        },
      ];
    } else {
      props.uischema.options.lookup = {
        route: "/lookups/users",
      };
      if (props.uischema.options.nameContains) {
        props.uischema.options.lookup.options = {
          name: props.uischema.options.nameContains,
        };
      }
    }
    return props;
  }, [props]);

  return MuiSelectorControl(newProps);
};

export const UserSelectorControl = {
  renderer: withJsonFormsControlProps(React.memo(UserSelector)),
  tester: UserSelectorTester,
};

export const UserSelectorCell = {
  cell: withJsonFormsCellProps(React.memo(UserSelector)),
  tester: rankWith(1000, uiTypeIs("DlxUserSelector")),
};
