import { useDispatch } from "react-redux";
import { alertError } from "../../state/alerts/actions";
import { sendConnectionActionRequest } from "../../state/sagas/connectionAction.saga";
import { actions } from "../../state/hooks/useApiResource";
import { logger } from "../../utils";
const log = logger("useLookup");

export const constructResouceParam = (
  lookup,
  labels = [],
  useResourceFilter = true
) => {
  if (useResourceFilter) {
    const paramName = ["orchestrations", "connections"].some((ele) =>
      lookup.route.includes(ele)
    )
      ? "resourceGroup"
      : "hasAccessToResourceGroup";
    if (labels.length > 0) {
      return `${paramName}=${labels[0].value}`;
    }
    if (paramName === "resourceGroup") {
      return `${paramName}=`;
    }
  }
  return null;
};

export const constructQueryParam = (params, resourceParam) => {
  const paramUrl = new URLSearchParams(params).toString();
  return [paramUrl, resourceParam].filter((param) => param).join("&");
};

export const useLookup = () => {
  const dispatch = useDispatch();

  const executeConnectionLookup = async (lookup) => {
    try {
      const { success, message, result } = await dispatch(
        sendConnectionActionRequest(lookup)
      );
      log({ lookup, success, message, result });
      if (!success) {
        throw new Error(`An error occurred looking up list items: ${message}`);
      }
      return { result };
    } catch (error) {
      dispatch(alertError(error.message || error));
      return { result: [] };
    }
  };

  const executeRouteLookup = async (lookup, labels, useResourceFilter) => {
    const resourceParam = constructResouceParam(lookup, labels, useResourceFilter);

    const param = lookup.options ? lookup.options : "";
    const queryParams = constructQueryParam(param, resourceParam);
    const route = queryParams ? `${lookup.route}?${queryParams}` : `${lookup.route}`;

    const response = await dispatch(
      actions.apiTransaction({
        route,
      })
    );
    log({ lookup, response });
    if (!response.error && Array.isArray(response.items)) {
      if (response.items.length === 1) {
        return { result: response.items[0].options };
      }
      return {
        result: response.items.map((item) => ({
          ...item,
          items: item.items || item.options,
          selectable: false,
        })),
      };
    }
    dispatch(alertError("An error occurred looking up selections"));
    return { result: [] };
  };

  const executeApiLookup = async (lookup, labels, useResourceFilter) => {
    const requests = Object.values(lookup)
      .filter(Boolean)
      .map((c) => {
        let path = c.href;
        const resourceParam = constructResouceParam(
          { c, route: c.href },
          labels,
          useResourceFilter
        );
        const param = lookup.options ? lookup.options : "";
        const queryParams = constructQueryParam(param, resourceParam);
        if (resourceParam !== "") {
          path += `?${queryParams}`;
        }
        return dispatch(
          actions.apiTransaction({
            route: path,
          })
        );
      });
    const lists = await Promise.allSettled(requests);
    log({ lists });
    return {
      result: lists
        .filter((i) => i.status === "fulfilled")
        .map((j) => j.value.items[0])
        .map((item) => ({
          label: item.label,
          value: item.label,
          items: item.options,
          selectable: false,
        })),
    };
  };

  const executeLookup = (lookup, labels = [], useResourceFilter) => {
    if (lookup.command) {
      return executeConnectionLookup(lookup);
    }
    if (lookup?.[0]?.kind === "api-resource") {
      return executeApiLookup(lookup, labels, useResourceFilter);
    }
    if (lookup.route) {
      return executeRouteLookup(lookup, labels, useResourceFilter);
    }
    dispatch(alertError("Unknown lookup used"));
    return null;
  };

  return { executeLookup };
};

export const buildPath = (connector, labels, step) => {
  const path = "/lookups/connections";
  const param = connector
    ? `compatibleWithConnectorId=${connector?.id || connector}`
    : "";
  const resourceParam =
    step?.kind === "event-query"
      ? ""
      : constructResouceParam({ route: path }, labels, true);

  const queryParams = constructQueryParam(param, resourceParam);

  return `${path}${
    queryParams ? `?${new URLSearchParams(queryParams).toString()}` : ""
  }`;
};
