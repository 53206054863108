import React, { useEffect, useState } from "react";
import Dialog from "@mui/material/Dialog";
import Button from "@mui/material/Button";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import { discoverProvider } from "../services/sso";
import { useSessionContext } from "../contexts/session/Provider";
import { useIdleTimer } from "react-idle-timer";
import { useCurrentUserContext } from "../contexts/current-user/Provider";
import { isInIframe } from "../utils";
import { openIframeSignin } from "@dlx/components/IFrame/RenderIFrame";

const DEFAULT_TIMEOUT = 30;

const getTimeoutForCustomer = (customer) => {
  const timeout = customer.idleSessionTimeout || DEFAULT_TIMEOUT;
  return timeout * 60 * 1000;
};

export function SessionIdleTimer() {
  const { isIdle, handleExpiredSession } = useSessionContext();
  const { customer } = useCurrentUserContext();

  useEffect(() => {
    if (isIdle) {
      openIdleDialog();
    } else {
      reset();
    }
  }, [isIdle]);

  const { reset } = useIdleTimer({
    onIdle: async () => {
      handleExpiredSession();
    },
    onAction: () => {
      reset();
    },
    throttle: 250,
    timeout: getTimeoutForCustomer(customer),
    crossTab: true,
  });

  return <IdleDialog />;
}

let openIdleDialog = null;
const IdleDialog = () => {
  const { handleLogout } = useSessionContext();
  const [open, setOpen] = useState(false);
  openIdleDialog = () => setOpen(true);
  return (
    <Dialog open={open}>
      <DialogTitle
        style={{
          border: "1px solid #dadce0",
          borderTopLeftRadius: "6px",
          borderTopRightRadius: "6px",
          backgroundColor: "#f6f6f6",
        }}
      >
        Your session has expired
      </DialogTitle>
      <DialogContent>
        <section className="tc pv4 pb5">Sign on again to continue.</section>
        <section className="tc">
          <Button
            variant="contained"
            color="inherit"
            disableElevation
            disableRipple
            onClick={() => {
              handleLogout();
              setOpen(false);
            }}
            style={{
              textTransform: "capitalize",
              marginRight: "16px",
            }}
          >
            Cancel
          </Button>
          <Button
            variant="contained"
            color="primary"
            disableElevation
            disableRipple
            onClick={() => {
              if (isInIframe()) {
                openIframeSignin();
              } else {
                discoverProvider({ action: "popup" });
              }
              setOpen(false);
            }}
            style={{
              textTransform: "capitalize",
            }}
          >
            Sign On
          </Button>
        </section>
      </DialogContent>
    </Dialog>
  );
};
