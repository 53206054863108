import { alertError } from "@dlx/state/alerts/actions";

export function removeLastChar(title, char) {
  return title[title.length - 1] === char ? title.slice(0, -1) : title;
}

export const objectCopy = (obj) => {
  try {
    return JSON.parse(JSON.stringify(obj));
  } catch (error) {
    return null;
  }
};

export const sortByField = (field) => (a, b) => {
  const fieldA = a[field]?.toLowerCase();
  const fieldB = b[field]?.toLowerCase();
  if (fieldA < fieldB) {
    return -1;
  }
  if (fieldA > fieldB) {
    return 1;
  }
  return 0;
};

export const sortBy = (array, field) => {
  array.sort(sortByField(field));
  return array;
};

export const getString = (value, field) => {
  if (typeof value === "string") {
    if (value.trim().length === 0) {
      return `(No ${field})`;
    }
    return value;
  }
  return JSON.stringify(value);
};

export const operators = {
  "is equal to": "==",
  "is not equal to": "!=",
  "is greater than": ">",
  "is greater than or equal to": ">=",
  "is less than": "<",
  "is less than or equal to": "<=",
  exists: "&&",
  "does not exist": "==",
};

export async function executeCreateConnection(dispatch, actions, connection) {
  const response = await dispatch(
    actions.createRecord({
      tableName: "connections",
      record: {
        ...connection,
        state: "enabled",
      },
      skipRedirect: true,
    })
  );
  if (response.error) {
    dispatch(alertError(response.error));
    return { error: response.error };
  }
  return { connectionId: response.id };
}

export const replaceDashes = (str) => str.replace(/-/g, " ");
