import React from "react";
import Autocomplete from "@mui/material/Autocomplete";
import { TextField } from "@mui/material";
import { useGetConnector } from "../../../state/hooks";
import { buildPath } from "@dlx/components/OrchestrationFlow/useLookup";
import useLookup from "@dlx/state/hooks/useLookup";

function ConnectionSelector({ connectorId, setConnectionId, labels }) {
  const { connector, fetched: connectorFetched } = useGetConnector(connectorId);

  const { options: connectionsList } = useLookup({
    path: buildPath(connector, labels),
  });

  const label =
    connectorFetched && connectionsList && connector
      ? `Select a ${connector.name}`
      : "Loading...";

  const handleOnChange = (_event, selected) => setConnectionId(selected?.value);

  return (
    <Autocomplete
      id="connection-selector"
      options={connectionsList || []}
      renderInput={(params) => <TextField {...params} label={label} />}
      getOptionLabel={(opt) => opt.label}
      onChange={handleOnChange}
    />
  );
}

export default ConnectionSelector;
