import React from "react";
import clsx from "clsx";
import ToolTip from "@dlx/atoms/Tooltip";
import { ExclamationIconSVG } from "../../icons";

function getCSS(container, icon, value) {
  return {
    container,
    icon,
    value,
  };
}

const SOLID = {
  PRIMARY: getCSS("bg-primary text-contrast", "", "border-primary-light"),
  SECONDARY: getCSS(
    "bg-secondary text-primary bn",
    "text-secondary",
    "border-secondary-dark text-default"
  ),
  ERROR: getCSS("bg-default ba border-error text-error", ""),
};

const OUTLINED = {
  PRIMARY: getCSS(
    "ba border-primary text-primary",
    "text-primary",
    "border-secondary text-default"
  ),
};

export const LABEL_CHIP_VARIANTS = Object.freeze({
  SOLID,
  OUTLINED,
});

/**
 * LabelChip component.
 * @component
 * @param {{
 *   label: string,
 *   value?: string | object,
 *   error?: string | boolean;
 *   tooltip?: any,
 *   leftIcon?: React.Component | React.JSX.Element,
 *   rightIcon?: React.Component | React.JSX.Element,
 *   onLeftIconClick?: React.MouseEventHandler<HTMLSpanElement>,
 *   onRightIconClick?: React.MouseEventHandler<HTMLSpanElement>,
 *   className?: string,
 *   variant?: object,
 *   dataTestId?: string,
 * }} props
 * @returns {JSX.Element} - The rendered LabelChip component.
 */
export default function LabelChip({
  label,
  value = null,
  error = null,
  tooltip,
  leftIcon = null,
  rightIcon = null,
  onLeftIconClick = null,
  onRightIconClick = null,
  className = "",
  variant = LABEL_CHIP_VARIANTS.OUTLINED.PRIMARY,
  dataTestId = "",
}) {
  return (
    <ToolTip text={tooltip}>
      <div
        className={clsx(
          "inline-flex items-center",
          "rounded4",
          "overflow-hidden nowrap",
          "font-small",
          "ph1",
          variant.container,
          className
        )}
        data-testid={dataTestId}
      >
        {leftIcon && (
          <span
            className={clsx("flex flex-column", variant.icon)}
            onClick={onLeftIconClick}
          >
            {leftIcon}
          </span>
        )}
        <span
          className={clsx("pa1", variant.label)}
          data-testid={`${dataTestId}_label`}
        >
          {label}
        </span>

        {value && (
          <span
            className={clsx("bl ph1 pv1 pl2 ml1", variant.value)}
            data-testid={`${dataTestId}_value`}
          >
            {value}
          </span>
        )}

        {error && <ExclamationIconSVG className="text-error mr1" size="12" />}

        {rightIcon && (
          <span
            className={clsx("flex flex-column", variant.icon)}
            onClick={onRightIconClick}
          >
            {rightIcon}
          </span>
        )}
      </div>
    </ToolTip>
  );
}
