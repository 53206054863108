import cloneDeep from "lodash/cloneDeep";
import set from "lodash/set";
import get from "lodash/get";
import merge from "lodash/merge";
import convertToYup from "json-schema-yup-transformer";

export const populateMappings = (uischema, stepData) => {
  if (!uischema?.mappings || !Array.isArray(uischema.mappings)) {
    return uischema;
  }
  const schemaCopy = cloneDeep(uischema);
  uischema.mappings.forEach((map) => {
    if (typeof map.source === "string" && /^\$\$/.test(map.source)) {
      set(schemaCopy, map.target, get(stepData, map.source.substr(2)));
    }
  });
  return schemaCopy;
};

// This is a hack to handle disabling readonly properties.
// @jsonforms/react supports them beginning in v3.0.
// Until we can upgrade to  React 18 and Material UI 5 we're stuck.

export const setReadOnlyUiFields = (schema, inputUiSchema) => {
  Object.entries(schema.properties).forEach(([key, value]) => {
    if (value.readonly || value.readOnly) {
      const field = getFieldForScope(inputUiSchema, key);
      if (field) {
        field.readonly = true;
      }
    }
  });
  return inputUiSchema;
};

function getFieldForScope(inputUiSchema, scope) {
  let o;
  if (!Array.isArray(inputUiSchema.elements)) {
    if (inputUiSchema.scope === `#/properties/${scope}`) {
      return inputUiSchema;
    }
  }
  inputUiSchema.elements.forEach(function iter(a) {
    if (a.scope === `#/properties/${scope}`) {
      o = a;
      return true;
    }
    return Array.isArray(a.elements) && a.elements.some(iter);
  });
  return o;
}

export const setReadOnlyFields = (schema, inputUiSchema) => {
  const uischema = setReadOnlyUiFields(schema, inputUiSchema);

  const shouldInputBeDisabled = (input) => {
    if (input.type !== "Control") {
      return false;
    }
    const key = input.scope.match("#/properties/(.*)")?.[1];
    const prop = schema.properties[key];
    if (!prop) {
      return false;
    }
    return input.readonly || input.options?.readonly || prop.readOnly;
  };

  const disableReadOnlyUiFields = (field) => {
    if (Array.isArray(field.elements)) {
      field.elements.forEach(disableReadOnlyUiFields);
      return field;
    }
    if (shouldInputBeDisabled(field)) {
      addDisableRule(field);
    }
    return field;
  };
  return disableReadOnlyUiFields(uischema);
};

const addDisableRule = (el) => {
  el.rule = {
    effect: "DISABLE",
    condition: true,
  };
  return el;
};

export const getInitialErrors = (input, yupSchema) => {
  try {
    yupSchema.validateSync(input, { abortEarly: false });
    return {};
  } catch (e) {
    const errors = {};
    e.inner.forEach((err) => {
      errors[err.path] = err.message;
    });
    return errors;
  }
};

export const validSchema = (schema) => {
  let valid = false;
  const properties = schema.properties && Object.keys(schema.properties).length;
  if (properties) {
    valid = true;
    Object.entries(schema.properties).forEach(([_key, value]) => {
      if (value) {
        const keys = Object.keys(value);
        valid = keys.includes("type");
      } else {
        valid = false;
      }
    });
  }
  return valid;
};

export const getDefaultDataForSchema = (schema, data) => {
  const yupSchema = convertToYup(schema);
  const defaults = yupSchema.default();
  return merge({}, defaults, data);
};
