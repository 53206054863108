import React, { useEffect, useState } from "react";
import styles from "./UserReadingsDialog.module.scss";
import { makeStyles } from "tss-react/mui";
import TextField from "@mui/material/TextField";
import InputAdornment from "@mui/material/InputAdornment";

const useStyles = makeStyles()((theme) => ({
  root: {
    display: "flex",
    flexWrap: "wrap",
  },
  margin: {
    margin: theme.spacing(1),
  },
  withoutLabel: {
    marginTop: theme.spacing(3),
  },
  textField: {},
}));

const UserReadingItem = ({ reading, readingType, updateReading }) => {
  const [errorMessage, setErrorMessage] = useState("");
  const { classes, cx } = useStyles();

  useEffect(() => {
    if (readingType.required && !reading?.value) {
      setErrorMessage(`${readingType.type} is required`);
    }
    if (reading?.value > readingType.max) {
      setErrorMessage(
        `${readingType.type} must not exceed ${readingType.max}${readingType.units}`
      );
    }
    if (reading?.value < readingType.min) {
      setErrorMessage(
        `${readingType.type} must be at least ${readingType.min}${readingType.units}`
      );
    }
  }, [reading, readingType]);
  return (
    <div className={styles.userReadingItem}>
      <TextField
        fullWidth
        label={readingType.type}
        id={readingType.type}
        className={cx(classes.margin, classes.textField)}
        value={reading?.value || ""}
        error={reading?.value > readingType.max || reading?.value < readingType.min}
        onChange={(e) => {
          updateReading({
            label: readingType.type,
            value: e.target.value,
            units: readingType.units,
          });
        }}
        InputProps={{
          endAdornment: (
            <InputAdornment position="start">{readingType.units}</InputAdornment>
          ),
        }}
        helperText={errorMessage}
        variant="outlined"
      />
    </div>
  );
};

export default UserReadingItem;
