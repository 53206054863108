import {
  call,
  put,
  select,
  takeEvery,
  takeLatest,
  takeLeading,
} from "redux-saga/effects";
import { types } from "./auth.actions";
import AuthService from "../../services/Auth";
import { replace } from "@superilya/connected-react-router-18";
import { logger } from "../../utils";
import { CLOSE_SOCKET, OPEN_SOCKET } from "../socket/socket.actions";
import { dbReissueTemporaryPassword } from "../reducers/dbAdapter";
import { alertError, alertSuccess } from "../alerts/actions";
import { handleError } from "../sagas/record.saga";

const log = logger("AuthSaga");

const authService = new AuthService();

function* handleAuthSuccess({ payload }) {
  yield put({ type: types.SET_USER, payload: { user: payload.user } });
  yield put({ type: OPEN_SOCKET });
  if (sessionStorage.getItem("redirectUrl")) {
    yield put(replace(sessionStorage.getItem("redirectUrl")));
  } else if (payload.redirectUrl) {
    yield put(replace(payload.redirectUrl));
  }
  yield call([sessionStorage, sessionStorage.removeItem], "redirectUrl");
}

function* authLogoutLeader(action) {
  log("logging out leader", action);
  try {
    yield call([authService, authService.logout]);
    log("logout success");
  } catch (e) {
    log("logout error", e);
  }
  yield put({ type: types.AUTH_LOGOUT_ALL_SESSIONS, broadcast: true });
}

function* authLogoutAllSessions() {
  log("logging out session");
  yield put({ type: CLOSE_SOCKET });
  yield put({ type: "CLEAR_STORE" });
  yield call([sessionStorage, sessionStorage.clear]);
}

const reissueTemporaryPasswordForUser = (id) => {
  return authService.fetch({ url: `users/${id}/confirmation`, method: "POST" });
};

export function* reissueTemporaryPasswordSaga({ payload }) {
  try {
    const permissions = yield select(() => payload.permissions);
    if (!permissions.includes("auth:modify")) {
      return yield put(
        alertError(
          "You do not have the necessary permissions to re-issue temporary passwords."
        )
      );
    }
    const requests = payload.ids.map(reissueTemporaryPasswordForUser);
    yield Promise.all(requests);
    return yield put(alertSuccess("Temporary password(s) successfully re-issued"));
  } catch (error) {
    return yield handleError(error);
  }
}

export default function* authSaga() {
  yield takeEvery(types.AUTH_SUCCESS, handleAuthSuccess);
  yield takeLatest(types.AUTH_LOGOUT_LEADER, authLogoutLeader);
  yield takeLatest(types.AUTH_LOGOUT_ALL_SESSIONS, authLogoutAllSessions);
  yield takeLeading(dbReissueTemporaryPassword, reissueTemporaryPasswordSaga);
}
