import React from "react";
import { uiTypeIs, rankWith } from "@jsonforms/core";
import { withJsonFormsCellProps, withJsonFormsControlProps } from "@jsonforms/react";
import MuiSelectorControl from "./MuiSelectorControl";

const ConnectionLookupTester = rankWith(
  103, //increase rank as needed
  uiTypeIs("DlxActionLookupSelector")
);

const ConnectionLookup = (props) => {
  props.uischema.options = {
    ...props.uischema.options,
    labelRequiresLookup: !props.uischema.options.simple,
    lookup: {
      connection: props.uischema.options.connection,
      command: props.uischema.options.action,
      options: props.uischema.options.actionOptions || {},
      credentials: props.uischema.options.actionCredentials || {},
    },
  };
  return MuiSelectorControl(props);
};

export const ConnectionLookupControl = {
  renderer: withJsonFormsControlProps(React.memo(ConnectionLookup)),
  tester: ConnectionLookupTester,
};

export const ConnectionLookupCell = {
  cell: withJsonFormsCellProps(React.memo(ConnectionLookup)),
  tester: ConnectionLookupTester,
};
