import React from "react";
import LabelChip, { LABEL_CHIP_VARIANTS } from "@dlx/atoms/LabelChip";
import { SyncIcon } from "../../../../icons";

const WaitingForReadingChip = ({ waitingForReading, waitingForSeries }) => {
  const label = waitingForSeries ? "Waiting for next in series..." : "Working...";
  return waitingForReading || waitingForSeries ? (
    <LabelChip
      label={label}
      leftIcon={<SyncIcon spin size={14} />}
      variant={LABEL_CHIP_VARIANTS.OUTLINED.PRIMARY}
      dataTestId={`dtid_reading_${label}`}
      className="mr2 mv1"
    />
  ) : null;
};

export default WaitingForReadingChip;
