import React from "react";
import {
  routerMiddleware,
  connectRouter,
  ConnectedRouter,
} from "@superilya/connected-react-router-18";
import history from "../services/History";
import { Router } from "react-router-dom";

export const connectedRouterMiddleware = routerMiddleware(history);
export const connectedRouterReducer = connectRouter(history);
export const ConnectedRouterProvider = ({ children, ...props }) => (
  <ConnectedRouter history={history} {...props}>
    <Router history={history}>{children}</Router>
  </ConnectedRouter>
);
