import React from "react";
import { NavLink } from "react-router-dom";
import styles from "./Sidebar.module.scss";
import { OrchestrationIcon, ConnectionsIcon } from "../../../icons";
import variables from "../../../assets/styles/variables.scss";
import DLXTooltip from "../../../components/DLXTooltip/DLXTooltip";
import { useCurrentUserContext } from "../../../contexts/current-user/Provider";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { snakeCase } from "lodash";

const routes = [
  {
    link: "/connections",
    label: "Connections",
    permissions: null,
    default: true,
    icon: (
      <ConnectionsIcon className={styles.orcIcon} color={variables.darkgraytext} />
    ),
  },
  {
    link: "/orchestrations",
    label: "Orchestrations",
    permissions: ["orchestrations:view"],
    default: false,
    icon: <OrchestrationIcon className={styles.orcIcon} />,
  },
  {
    link: "/eventstream",
    icon: <FontAwesomeIcon icon="stream" className={styles.menuIcon} />,
    label: "Event Stream",
    permissions: ["eventstream:view"],
    default: false,
  },
  {
    link: "/audittrail",
    icon: <FontAwesomeIcon icon="list" className={styles.menuIcon} />,

    label: "Audit Trail",
    permissions: ["audit:view"],
    default: false,
  },
  {
    link: "/administration",
    icon: <FontAwesomeIcon icon="users" className={styles.menuIcon} />,

    label: "Administration",
    permissions: ["auth:view", "config:reasons"],
    default: false,
  },
];

export default function Sidebar() {
  const {
    currentUser: { permissions },
  } = useCurrentUserContext();

  return (
    <nav className={styles.root}>
      <div className={styles.menuItems}>
        {routes.map((route, i) => {
          const canAccess =
            route.permissions === null ||
            route.permissions.some((p) => permissions.includes(p));
          if (!canAccess) {
            return null;
          }
          return (
            <DLXTooltip
              key={i}
              placement="right"
              text={route.label}
              className={styles.sidebarTooltip}
            >
              <NavLink
                to={route.link}
                className={styles.item}
                activeClassName={styles.active}
                data-testid={snakeCase(`dtid_nav_${route.label}`)}
              >
                <span
                  style={{
                    pointerEvents: "none",
                  }}
                >
                  {route.icon}
                  <div className={styles.label}>{route.label}</div>
                </span>
              </NavLink>
            </DLXTooltip>
          );
        })}
      </div>
    </nav>
  );
}
