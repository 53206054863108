import React, { useState, useEffect } from "react";
import { CircularProgress, TextField } from "@mui/material";
import Autocomplete from "@mui/material/Autocomplete";
import { makeStyles } from "tss-react/mui";
import { colors } from "@dlx/assets/styles";
import { useEditorContext } from "../context";

const useStyles = makeStyles()({
  tag: {
    backgroundColor: colors.bgDefault,
    lineHeight: "100%",
  },
  popupIndicator: {
    color: colors.textContrast,
  },
  clearIndicator: {
    color: colors.textContrast,
    padding: "6px 4px !important",
  },
  inputRoot: {
    paddingBottom: "0 !important",
    "&:before, &:after, &:hover": {
      border: "0 !important",
    },
  },
  input: {
    padding: "4px 8px !important",
    minWidth: "80px !important",
  },
});

const doesAcceptArray = (type) => {
  return (Array.isArray(type) && type.includes("array")) || type === "array";
};

const createOptionObjects = (schema) => {
  return schema?.enum ? schema.enum.map((o) => ({ label: `${o}`, value: o })) : [];
};

const LookupValueSelector = ({ value, setValue, schema }) => {
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);
  const [options, setOptions] = useState(createOptionObjects(schema));
  const acceptsArray = doesAcceptArray(schema?.type);
  const [selection, setSelection] = useState(acceptsArray ? [] : null);
  const { executeLookup } = useEditorContext();
  const { classes } = useStyles();

  useEffect(() => {
    if (schema.lookup) {
      fetchLookup(schema.lookup);
    }
  }, [schema.lookup]);

  useEffect(() => {
    if (!loading) {
      if (acceptsArray) {
        const selectedValue = (value || [])
          .map((v) => options.find((o) => o.value === (v.value || v)))
          .filter(Boolean);

        setSelection(selectedValue);
      } else {
        setSelection(options.find((o) => o.value === value) || "");
      }
    }
  }, [loading, value, options, acceptsArray]);

  const fetchLookup = async (lookup) => {
    try {
      setLoading(true);
      const items = await executeLookup(lookup);
      setOptions(items);
    } catch (e) {
      setError(e);
    } finally {
      setLoading(false);
    }
  };

  const handleOnChange = (_e, v) => {
    setValue(Array.isArray(v) ? v.map((i) => i.value) : v?.value);
  };

  return (
    <div className="w100" style={{ minWidth: "350px" }}>
      <Autocomplete
        classes={classes}
        fullWidth
        multiple={acceptsArray}
        disableCloseOnSelect={acceptsArray}
        id="expression-lookup"
        size="small"
        options={options}
        disabled={loading}
        loading={loading}
        groupBy={(item) => item?.group}
        getOptionLabel={(o) => o?.label || ""}
        value={selection}
        onChange={handleOnChange}
        renderInput={(params) => (
          <TextField
            variant="standard"
            autoFocus
            fullWidth
            {...params}
            placeholder={error?.message || error || "Select"}
            InputProps={{
              ...params.InputProps,
              style: { color: "white" },
              endAdornment: loading ? (
                <CircularProgress size={15} style={{ color: "white" }} />
              ) : (
                params.InputProps.endAdornment
              ),
            }}
          />
        )}
      />
    </div>
  );
};

export default LookupValueSelector;
