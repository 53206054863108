import React, { useEffect, useCallback } from "react";
import Card from "@mui/material/Card";
import { CardContent, CardHeader } from "@mui/material";
import { Header } from "../../../components/header/Header";
import useOrchestrationExecutionViewer from "./useOrchestrationExecutionViewer";
import OrchestrationExecutionViewer from "./OrchestrationExecutionViewer";
import styles from "./OrchestrationExecutionViewer.module.scss";
import { useDispatch } from "react-redux";
import { loadOrchestrationsPage } from "../../../components/orchestrationeditor/state/actions";
import { abortOrchestration } from "../../../state/orchestrations/orchestration.reducer";
import { useCurrentUserContext } from "../../../contexts/current-user/Provider";

const IOError = ({ message }) => <div>{message}</div>;

export default function OrchestrationExecutionViewerPage(overrides = {}) {
  const props = useOrchestrationExecutionViewer(overrides);
  const dispatch = useDispatch();
  const { currentUser } = useCurrentUserContext();
  props.id = props.executionId;

  const abortExecution = useCallback(
    async (execution) => {
      dispatch(abortOrchestration(currentUser, execution));
    },
    [currentUser, dispatch]
  );

  useEffect(() => {
    dispatch(loadOrchestrationsPage());
  }, [dispatch]);

  const Layout = props.env === "iframe" ? EmbeddedLayout : FullLayout;

  if (props.error) {
    return (
      <Layout>
        <IOError message={props.error} />
      </Layout>
    );
  }
  return (
    <Layout>
      <OrchestrationExecutionViewer
        {...props}
        onlyCurrentStep={true}
        abortExecution={abortExecution}
      />
    </Layout>
  );
}

export function FullLayout({ children }) {
  return (
    <div className={styles.wrapper}>
      <div className={styles.container}>
        <Card>
          <CardHeader title={<Header />} style={{ backgroundColor: "#f6f6f6" }} />
          <CardContent className={styles.cardContent}>{children}</CardContent>
        </Card>
      </div>
    </div>
  );
}

export function EmbeddedLayout({ children }) {
  return (
    <Card
      style={{
        position: "absolute",
        width: "100%",
        height: "100%",
        borderRadius: 0,
        border: "none",
        boxShadow: "none",
        background: "white",
      }}
    >
      <CardHeader title={<Header />} style={{ backgroundColor: "#f6f6f6" }} />
      <CardContent className={styles.iframeCardContent}>{children}</CardContent>
    </Card>
  );
}
