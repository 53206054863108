import React, { useEffect } from "react";
import { makeStyles } from "tss-react/mui";
import Button from "@mui/material/Button";
import variables from "../assets/styles/variables.scss";
import { useDispatch, useSelector } from "react-redux";
import {
  SnackbarProvider as NotistackSnackbarProvider,
  useSnackbar,
} from "notistack";
import { RESET_ERROR } from "../state/constants";
import { selectAlerts } from "../state/alerts/selectors.js";

const useStyles = makeStyles()({
  button: {
    color: "white",
  },
  success: {
    backgroundColor: variables.green,
    border: "6px",
    boxShadow: variables.boxshadow,
    color: variables.white,
    letterSpacing: "0.7px",
    fontSize: 14,
    fontWeight: "bold",
  },
  error: {
    backgroundColor: variables.red,
    border: "6px",
    boxShadow: variables.boxshadow,
    color: variables.white,
    letterSpacing: "0.7px",
    fontSize: 14,
    fontWeight: "bold",
  },
  warning: {
    backgroundColor: variables.yellow,
    border: "6px",
    boxShadow: variables.boxshadow,
    color: variables.darkgraytext,
    letterSpacing: "0.7px",
    fontSize: 14,
    fontWeight: "bold",
  },
  info: {
    backgroundColor: variables.darkblue,
    border: "6px",
    boxShadow: variables.boxshadow,
    color: variables.white,
    letterSpacing: "0.7px",
    fontSize: 14,
    fontWeight: "bold",
  },
});

function Notifier() {
  const dispatch = useDispatch();
  const alert = useSelector(selectAlerts);
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  useEffect(() => {
    if (alert.display) {
      enqueueSnackbar(alert.message, alert.options);
    }
  }, [alert, closeSnackbar, enqueueSnackbar, dispatch]);

  return null;
}

export default function SnackbarProvider({ children }) {
  const dispatch = useDispatch();
  const notistackRef = React.createRef();
  const onClickDismiss = (key) => () => {
    dispatch({ type: RESET_ERROR });
    notistackRef.current.closeSnackbar(key);
  };
  const { classes } = useStyles();

  return (
    <NotistackSnackbarProvider
      maxSnack={3}
      preventDuplicate={true}
      autoHideDuration={3 * 1000}
      anchorOrigin={{
        vertical: "top",
        horizontal: "center",
      }}
      ref={notistackRef}
      action={(key) => (
        <Button
          size="small"
          classes={{
            root: classes.button,
          }}
          onClick={onClickDismiss(key)}
        >
          Dismiss
        </Button>
      )}
      classes={{
        variantSuccess: classes.success,
        variantError: classes.error,
        variantWarning: classes.warning,
        variantInfo: classes.info,
      }}
    >
      <Notifier />
      {children}
    </NotistackSnackbarProvider>
  );
}
