import React from "react";
import Modal from "@mui/material/Modal";
import DLXButton from "../DLXButton/DLXButton";
import styles from "./DLXModal.module.scss";
import { ScitaraLogo } from "../../icons";
import clsx from "clsx";
import { snakeCase } from "lodash";

const modalBackdropFilter = {
  backdropFilter: "blur(7px)",
};

function getModalStyle() {
  const top = 50;
  const left = 50;

  return {
    top: `${top}%`,
    left: `${left}%`,
    transform: `translate(-50%, -50%)`,
    position: "absolute",
    background: "#FFF",
    margin: "auto",
    borderRadius: "4px",
  };
}

function DLXModal({
  backdropFilter,
  cancelButtonLabel,
  dataTestId,
  footer,
  hideSubmitButton,
  onCancel,
  onSubmit,
  open,
  showLogo = false,
  size = "medium",
  submitButtonLabel,
  submitDisabled,
  title,
  type,
  ...props
}) {
  const [modalStyle] = React.useState(getModalStyle);

  const modalSize = {
    [styles.modal_extra_small]: size === "extra_small",
    [styles.modal_large]: size === "large",
    [styles.modal_medium]: size === "medium",
    [styles.modal_micro]: size === "micro",
    [styles.modal_small]: size === "small",
  };

  cancelButtonLabel = cancelButtonLabel ? cancelButtonLabel : "cancel";

  submitButtonLabel = submitButtonLabel ? submitButtonLabel : "submit";

  const checkDataTestId = dataTestId ? dataTestId : "dtid_submit_button";

  return (
    <Modal
      open={open}
      onClose={onCancel}
      aria-labelledby="simple-modal-title"
      aria-describedby="simple-modal-description"
      style={backdropFilter ? modalBackdropFilter : null}
      data-testid={snakeCase(`dtid_${title}`)}
    >
      <div
        style={modalStyle}
        className={clsx(modalSize, styles.modalContainer)}
        data-testid="dtid_dlx_modal_modal_micro"
      >
        <div
          className={clsx(
            styles.modal_header,
            size === "micro" && styles.dialog_header
          )}
        >
          <div
            id="simple-modal-title"
            className={styles.modal_title}
            data-testid="dtid_dlx_modal_title"
          >
            {title}
          </div>
        </div>
        <div
          id="simple-modal-description"
          className={styles.modal_body}
          data-testid="dtid_dlx_modal_body"
        >
          {props.children}
        </div>
        <div
          className={clsx(
            styles.modal_footer,
            size === "micro" && styles.dialog_footer
          )}
        >
          {type !== "info" && footer === undefined ? (
            <>
              <div className={styles.logo_container}>
                {showLogo && (
                  <div style={{ display: "flex" }}>
                    <ScitaraLogo style={{ width: "150px" }} />
                  </div>
                )}
              </div>
              <div style={{ display: "flex", textAlign: "right" }}>
                {onCancel && (
                  <DLXButton
                    label={cancelButtonLabel}
                    variant="textButton"
                    handleClick={onCancel}
                    dataTestId="dtid_cancel_button"
                  />
                )}
                {onSubmit && !hideSubmitButton && (
                  <DLXButton
                    label={submitButtonLabel}
                    variant="contained"
                    color="primary"
                    handleClick={onSubmit}
                    disabled={submitDisabled}
                    dataTestId={checkDataTestId}
                  />
                )}
              </div>
            </>
          ) : (
            <div style={{ margin: "-4px" }}>{footer}</div>
          )}
        </div>
      </div>
    </Modal>
  );
}
export default DLXModal;
