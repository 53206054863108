import React, { Fragment } from "react";
import LabelChip, { LABEL_CHIP_VARIANTS } from "@dlx/atoms/LabelChip";

import styles from "../ConnectionInterfaceTable.module.scss";
import { InfoCircleIcon } from "../../../../icons";

const Attributes = ({ attributes }) => {
  if (!attributes) {
    return null;
  }
  return (
    <Fragment>
      <tr>
        <td>Attributes</td>
        <td>
          <span className={styles.chipHolder}>
            {Object.keys(attributes).map((key, i) => (
              <LabelChip
                key={i}
                label={key}
                value={typeof attributes[key] === "object" ? "..." : attributes[key]}
                leftIcon={<InfoCircleIcon className="font-medium" />}
                variant={LABEL_CHIP_VARIANTS.OUTLINED.PRIMARY}
                dataTestId={`dtid_reading_attributes_${key}`}
                className="mr2 mv1"
              />
            ))}
          </span>
        </td>
      </tr>
    </Fragment>
  );
};

export default Attributes;
