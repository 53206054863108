import { USER_MISMATCH } from "@dlx/state/constants";
import decode from "jwt-decode";
import config from "config";
import { logger } from "../utils";
const log = logger("tokens");

export const getStoredIamToken = () => {
  return JSON.parse(localStorage.getItem("iamToken") || "false");
};

export const storeIamToken = (token) => {
  return localStorage.setItem("iamToken", JSON.stringify(token));
};

export const fetchUserPermissions = async (token) => {
  const userInfo = decode(token.id_token);
  const response = await fetch(
    `${config.apiUrl}/users/${userInfo.sub}/permissions`,
    {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: "Bearer " + token.access_token,
      },
      method: "GET",
    }
  );
  return await response.json();
};

export const storeCurrentUser = async (token) => {
  try {
    const userInfo = await fetchUserPermissions(token);
    const user = decode(token.id_token);
    user.id = user.sub;
    user.permissions = userInfo.permissions;
    user.resourcePolicyGrants = userInfo.resourcePolicyGrants;
    localStorage.setItem("currentUser", JSON.stringify(user));
    return user;
  } catch (error) {
    log(error);
    throw error;
  }
};

export const getStoredCurrentUser = () => {
  const token = getStoredIamToken();
  if (!token) {
    return null;
  }
  try {
    const user = decode(token.id_token);
    user.id = user.sub;
    return user;
  } catch (error) {
    log(error);
    return null;
  }
};

export const isTokenExpired = (token) => {
  try {
    const expiresAt = decode(token).exp;
    if (!expiresAt) {
      throw new Error("NoExp");
    }
    return Date.now() >= expiresAt * 1000;
  } catch (error) {
    log(error);
  }
  return true;
};

export const getStoredAccessToken = () => {
  try {
    const token = getStoredIamToken();
    return token.access_token;
  } catch (error) {
    log(error);
    return null;
  }
};

export const decodeUser = (token) => {
  const user = decode(token.id_token);
  user.id = user.sub;
  return user;
};

export const validateCurrentUser = (token, nonFatal) => {
  const currentUser = getStoredCurrentUser();
  const user = decodeUser(token);
  if (currentUser && user.sub !== currentUser.sub) {
    localStorage.clear();
    if (!nonFatal) {
      throw new Error(USER_MISMATCH);
    }
  }
  return true;
};

export const getAccessToken = () => {
  return getStoredAccessToken();
};
