import React from "react";
import clsx from "clsx";

import styles from "./styles.module.scss";

function Spinner({ size = 14, className = "", style = {} }) {
  const space = `${size}px`;
  return (
    <span
      className={clsx(styles.spinner, className)}
      style={{ height: space, width: space, ...style }}
    ></span>
  );
}

export default Spinner;
