import React, { useState } from "react";
import clsx from "clsx";

import ConfirmInline from "@dlx/atoms/ConfirmInline";
import Button from "@dlx/atoms/Button";
import Popover from "@dlx/atoms/Popover";

import { DeleteIcon, EllipsisIcon, TransformIcon } from "../../../icons";

import { getFunctionCallElement } from "../util";
import { AST_ELEMENT_TYPES } from "../constants";
import { IconButton } from "../Wrappers";
import { FunctionSelector } from "../Selectors";

function ElementActions({
  element,
  setElement,
  deleteElement,
  functionInputType,
  hasFunction,
  canApplyTransforms = true,
  children,
}) {
  const [isDeleting, setDeleting] = useState(false);

  const onSelectTransform = (transform) => {
    const newElement = getFunctionCallElement(transform, element);
    setElement(newElement);
  };

  const itemCss =
    "flex items-center pv2 ph3 pointer bb border-secondary-light bg-hover-secondary-light";
  let popoverFunctionSelector = null;

  return (
    <>
      {!isDeleting && children}
      {canApplyTransforms && !hasFunction && !isDeleting && (
        <>
          <Popover autoclose={true} dataTestId="dtid_element_actions">
            <IconButton
              className="text-primary-light text-hover-contrast ml2"
              size={32}
              dataTestId="dtid_element_options"
            >
              <EllipsisIcon size={24} />
            </IconButton>
            <section>
              <div
                className={clsx(itemCss, "dtid_element_options_add_transform")}
                onClick={() => popoverFunctionSelector.open()}
              >
                <TransformIcon size={24} color="inherit" />
                <span className="pl2">Add Transform</span>
              </div>
              {element.type === AST_ELEMENT_TYPES.FUNCTION_CALL && (
                <div
                  className={clsx(itemCss, "dtid_element_options_clear")}
                  onClick={() => setDeleting(true)}
                >
                  <DeleteIcon size={24} color="inherit" />
                  <span className="pl2">Remove Transform</span>
                </div>
              )}
            </section>
          </Popover>
          <FunctionSelector
            showAll={element.type === AST_ELEMENT_TYPES.IDENTIFIER}
            inputType={functionInputType}
            onSelect={onSelectTransform}
            onInit={(args) => (popoverFunctionSelector = args)}
          >
            {" "}
          </FunctionSelector>
        </>
      )}
      {isDeleting && (
        <ConfirmInline
          onClose={() => setDeleting(false)}
          className={"ph3 pv1 flex items-center"}
        >
          <span>Remove {element.name || "value"}?</span>
          <Button
            className="bn pa1 ml2 bg-primary-light bg-hover-default text-primary"
            onClick={() => deleteElement()}
          >
            Yes
          </Button>
          <Button className="pa1 ml2 text-contrast">No</Button>
        </ConfirmInline>
      )}
    </>
  );
}

export default ElementActions;
