import Logo from "@dlx/components/header/Logo";
import { Box, Container, Typography } from "@mui/material";
import React from "react";
import { useTitle } from "react-use";
import "@dlx/assets/styles";
import { useHistory } from "react-router-dom";

export const SignOffSuccess = () => {
  const history = useHistory();
  const routeToLogin = () => {
    history.replace("/");
  };
  useTitle("You have signed off from the Scitara DLX.");
  return (
    <Box
      height="100vh"
      width="100%"
      display="flex"
      alignItems="center"
      justifyContent="center"
    >
      <Container maxWidth="sm">
        <Logo />
        <Typography variant="h2">
          You have signed off from the Scitara DLX.
        </Typography>
        <div className="tc pt6">
          <button
            onClick={routeToLogin}
            className="font-large pointer mt3 bn bg-primary bg-hover-primary-dark text-contrast ph3 pv2 inline-block rounded2"
          >
            Sign On
          </button>
        </div>
      </Container>
    </Box>
  );
};
