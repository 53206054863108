import React, { useEffect, useRef, useState } from "react";
import { Popover } from "@mui/material";

const BOTTOM_LEFT = {
  vertical: "top",
  horizontal: "left",
};

const BOTTOM_RIGHT = {
  vertical: "top",
  horizontal: "right",
};

const BOTTOM_CENTER = {
  vertical: "top",
  horizontal: "center",
};

export const POPOVER_POSITIONS = Object.freeze({
  BOTTOM_LEFT,
  BOTTOM_RIGHT,
  BOTTOM_CENTER,
});

function PopoverWrapper({
  children,
  autoclose = false,
  onOpen = null,
  onClose = null,
  onInit = null,
  position = BOTTOM_LEFT,
  dataTestId = "",
  className = "",
}) {
  const [anchorEl, setAnchorEl] = useState(null);
  const anchorRef = useRef(null);

  const open = () => {
    setAnchorEl(anchorRef.current);
    onOpen && onOpen();
  };

  const close = () => {
    setAnchorEl(null);
    onClose && onClose();
  };

  const handleAutoClose = () => {
    autoclose && close();
  };

  useEffect(() => {
    onInit && onInit({ open, close });
  });
  const [dropdownAnchor, ...dropdownBody] = children;
  return (
    <>
      <div
        data-testid={`${dataTestId}_popover_anchor`}
        className={`${className} ${dataTestId}_popover_anchor`}
        ref={anchorRef}
        onClick={open}
      >
        {dropdownAnchor}
      </div>
      <Popover
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        transformOrigin={position}
        onClose={close}
      >
        <div
          data-testid={`${dataTestId}_popover_body`}
          className={`${dataTestId}_popover_anchor`}
          onClick={handleAutoClose}
        >
          {dropdownBody}
        </div>
      </Popover>
    </>
  );
}

export default PopoverWrapper;
