import { push } from "@superilya/connected-react-router-18";
import * as effects from "redux-saga/effects";
import { client } from "../../../services/api/client";
import { sendConnectionActionRequest } from "../../../state/sagas/connectionAction.saga";
import {
  dbLoadSuccess,
  dbRecordRetrieved,
  dbRecordUpdated,
  dbRecordCreated,
  dbLoad,
} from "../../../state/reducers/dbAdapter";
import api from "../../../services/api";
import logger from "../../../utils/logger";
import { alertError, alertSuccess } from "../../../state/alerts/actions";
import * as actions from "./actions";
import * as constants from "./constants";
import { CANCELED } from "../../../state/constants";

const log = logger("orchestrationEditor.saga");

function* getFullConnection({ connectionId }) {
  const connection = yield effects.call(api.connections.get, { id: connectionId });
  const connector = yield effects.call(api.connectors.get, {
    id: connection.connector.id,
  });
  yield effects.put(
    dbRecordRetrieved({ tableName: "connections", record: connection })
  );
  yield effects.put(
    dbRecordRetrieved({ tableName: "connectors", record: connector })
  );
  return {
    connection,
    connector,
  };
}

const cache = {
  trigger: {},
  command: {},
};

const clearCache = () => {
  cache.trigger = {};
  cache.command = {};
};

const getTrigger = (key) => {
  return cache.trigger[key];
};

const setTrigger = (key, events = {}) => {
  cache.trigger[key] = events;
};

const setOptions = (key, options) => {
  cache.command[key] = options;
};
const getOptions = (key) => {
  return cache.command[key];
};

export const getCacheKey = (payload = {}) => {
  return JSON.stringify(
    payload,
    Object.keys(payload).sort((a, b) => {
      if (a > b) {
        return 1; // Indicates that 'a' should come after 'b'
      }
      if (a < b) {
        return -1; // Indicates that 'a' should come before 'b'
      }
      return 0; // Indicates that 'a' and 'b' are equal in terms of sorting
    })
  );
};

function* handleTriggerEvents({ payload }) {
  log(`getting trigger events for connection ${payload.connectionId}`);
  const key = getCacheKey(payload);
  try {
    const { connector } = yield effects.call(getFullConnection, payload);
    setTrigger(key, connector.events);
  } catch (error) {
    log("someting went fetching trigger events", error);
    setTrigger(key, {});
    yield effects.put(actions.editorError({ key: "triggerEvents", error }));
  }
  yield effects.put(actions.setTriggerEvents(getTrigger(key)));
}

function* loadOrchestrationDependencies() {
  log(`loading orchestration dependencies`);
  yield effects.put(dbLoad({ tableName: "orchestrations" }));
  yield effects.put(dbLoad({ tableName: "connectors", filter: "state=current" }));
  yield effects.put(dbLoad({ tableName: "connections" }));
  try {
    const loaded = yield effects.select((state) => state.actors.loaded);
    if (!loaded) {
      const [actors] = yield effects.all([effects.call(api.actors.list)]);
      yield effects.put(dbLoadSuccess({ tableName: "actors", data: actors }));
    }
  } catch (error) {
    log("error loading orchestration editor dependencies", error);
  }
}

const newOrchestration = {
  id: null,
  name: "",
  steps: [],
  state: "disabled",
  attributes: {},
  trigger: {
    kind: "event",
  },
};

function* loadOrchestration(payload) {
  log(`loading orchestration`, payload);
  try {
    switch (payload.mode) {
      case "view": {
        break;
      }
      case "new": {
        yield effects.put(actions.setOrchestration(newOrchestration));
        break;
      }
      case "edit": {
        let orc = null;
        let exec = null;
        try {
          orc = yield effects.call(getOrchestration, payload);
          exec = yield effects.call(api.orchestrations.lastExecution, orc);
        } catch (e) {
          log("No execution available");
        }
        if (
          orc?.orchestrationVersion === exec?.orchestration?.orchestrationVersion
        ) {
          yield effects.put(actions.setOrchestration(orc, exec?.id));
        } else {
          yield effects.put(actions.setOrchestration(orc));
        }
        break;
      }
      case "copy": {
        const { id, ...orc } = yield effects.call(getOrchestration, payload);
        yield effects.put(actions.setOrchestration({ ...newOrchestration, ...orc }));
        break;
      }
      case "import": {
        yield effects.put(actions.setOrchestration(payload.orchestration));
        break;
      }
      default:
        throw new Error("unknown mode", payload.mode);
    }
  } catch (error) {
    log("error loading orchestration", error);
    yield effects.put(actions.editorError({ key: "loadOrchestration", error }));
    yield effects.put(push(constants.ORCHESTRATIONS_PATH));
  }
}

function* getOrchestration({ orchestrationId }) {
  const orc = yield effects.call(api.orchestrations.get, {
    id: orchestrationId,
  });
  yield effects.put(dbRecordRetrieved({ tableName: "orchestrations", record: orc }));
  return orc;
}

function* updateOrchestration(values) {
  const record = yield effects.call(api.orchestrations.update, values);
  yield effects.put(dbRecordUpdated({ tableName: "orchestrations", record }));
  yield effects.put(actions.saveOrchestrationSuccess(record));
  yield effects.put(alertSuccess(`Orchestration saved successfully`));
  return record;
}

function* toggleOrcState(action) {
  try {
    const { id, name, state, orchestrationVersion } = action.payload.orchestration;

    const record = yield effects.call(api.orchestrations.update, {
      id,
      name,
      state: state === "enabled" ? "disabled" : "enabled",
      orchestrationVersion: orchestrationVersion,
    });
    yield effects.put(dbRecordUpdated({ tableName: "orchestrations", record }));
    yield effects.put(alertSuccess(`Orchestration is now ${record.state}`));
  } catch (error) {
    if (!isCanceled(error)) {
      let message = error.message;
      if (error.isAxiosError) {
        message = error.response?.data?.message;
      }
      yield effects.put(alertError(`Error enabling orchestration! ${message}`));
    }
  }
}

function* createOrchestration(values) {
  const record = yield effects.call(api.orchestrations.create, values);
  yield effects.put(dbRecordCreated({ tableName: "orchestrations", record }));
  yield effects.put(actions.saveOrchestrationSuccess(record));
  yield effects.put(push(`${constants.ORCHESTRATIONS_PATH}/${record.id}/edit`));
  yield effects.put(alertSuccess(`Orchestration saved successfully`));
  return record;
}

function* saveOrchestration({ payload: { orchestration, mode } }) {
  try {
    if (mode === "update") {
      yield effects.call(updateOrchestration, orchestration);
    }
    if (mode === "new") {
      yield effects.call(createOrchestration, orchestration);
    }
  } catch (error) {
    if (!isCanceled(error)) {
      log("error saving orchestration", error);
      let message = error.message;
      if (error.isAxiosError) {
        const data = error.response.data;
        yield effects.put(actions.saveOrchestrationFailure({ mode, error: data }));
        message = data.message;
        if (data.statusCode === 409) {
          message = "An orchestration with that name already exists";
        }
      }
      yield effects.put(alertError(message));
    }
  }
}

const isCanceled = (error) => error === null || error.message === CANCELED;

/** @returns {Promise<Option[]>} */
const fetchLookups = async (commands) => {
  if (typeof commands === "string") {
    return client.get(commands).then((res) => res.data.items);
  }
  const requests = commands.map((c) => client.get(c.href));
  const promises = await Promise.allSettled(requests);
  return promises
    .filter((p) => p.status === "fulfilled")
    .map((p) => p.value)
    .flatMap(({ data }) =>
      data.items.flatMap((group) => {
        const labelOption = (o) => ({ ...o, group: group.label });
        return group.options.map(labelOption);
      })
    );
};

/** @returns {Connection["id"]} */
const getConnectionId = (payload) => {
  if (typeof payload.connectionId === "string") {
    return payload.connectionId;
  }
  return payload.connectionId.value;
};

/**
 * @param {{
 *  payload: {
 *   type: string,
 *   command: string | Lookup[],
 *   connectionId: Connection["id"]
 *  }
 * }} props
 */
function* getCommandOptions({ payload }) {
  const { type, command } = payload;
  const key = getCacheKey(payload);
  /** @type {Option[]} */
  let commandOptions = [];
  try {
    if (type === "api") {
      commandOptions = yield effects.call(fetchLookups, command);
    }
    if (type === "socket") {
      /** @type {{message: string, success: boolean, data: {label: string, value: string}[]}} */
      const response = yield effects.putResolve(
        sendConnectionActionRequest({
          connectionId: getConnectionId(payload),
          actionName: command,
        })
      );
      if (!response.success) {
        throw new Error(response.message);
      }
      commandOptions = response.result;
    }
    setOptions(key, commandOptions);
  } catch (error) {
    log("error getting command options", error);
    yield effects.put(actions.editorError({ key: "commandOptions", error }));
  }
  yield effects.put(
    actions.setCommandOptions({ commandOptions: getOptions(key), key })
  );
}

function* editorSaga(action) {
  yield effects.takeEvery(constants.GET_TRIGGER_EVENTS, handleTriggerEvents);
  yield effects.takeLeading(constants.GET_COMMAND_OPTIONS, getCommandOptions);
  yield effects.call(loadOrchestration, action.payload);
}

export function* editorSagaMonitor() {
  while (true) {
    const action = yield effects.take(constants.OPEN_EDITOR);
    log("editorSaga:opening...");
    const task = yield effects.fork(editorSaga, action);
    while (true) {
      const p = yield effects.take(constants.CLOSE_EDITOR);
      if (p?.payload?.prompt) {
        const [cancel, confirm] = yield effects.race([
          effects.take(constants.CLOSE_EDITOR_CANCEL),
          effects.take(constants.CLOSE_EDITOR_CONFIRM),
        ]);
        if (confirm && !cancel) {
          break;
        }
      } else {
        break;
      }
    }
    log("editorSaga:editor closed");
    yield effects.call(clearCache);
    yield effects.cancel(task);
    yield effects.put(push(action.payload.redirectUrl));
  }
}

export default function* mainSaga() {
  try {
    yield effects.takeLatest(
      constants.LOAD_ORCHESTRATIONS_PAGE,
      loadOrchestrationDependencies
    );
    yield effects.takeEvery(constants.SAVE_ORCHESTRATION, saveOrchestration);
    yield effects.takeEvery(constants.TOGGLE_ORCHESTRATION_STATE, toggleOrcState);
    yield effects.fork(editorSagaMonitor);
  } catch (error) {
    log("error in orc editor saga", error);
  }
}
