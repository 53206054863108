import React, { useCallback, useState } from "react";
import { rankWith, or, uiTypeIs } from "@jsonforms/core";
import { withJsonFormsCellProps, withJsonFormsControlProps } from "@jsonforms/react";
import { Button } from "@mui/material";
import merge from "lodash/merge";
import DLXTooltip from "../../../DLXTooltip/DLXTooltip";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useJsonFormContext } from "../../JsonForm";
import { get } from "lodash";
import { useGetConnection } from "../../../../state/hooks";
const ConnectionAction = ({
  config,
  enabled,
  handleChange,
  label,
  path,
  uischema,
  visible,
}) => {
  const [working, setWorking] = useState(false);
  const { connection } = useGetConnection(uischema?.options?.connection);
  const { executeLookupCommand } = useJsonFormContext();

  const rowPath = path.slice(0, path.lastIndexOf("."));
  const targetPath =
    rowPath && uischema.options.targetField
      ? `${rowPath}.${uischema.options.targetField}`
      : path;

  const appliedUiSchemaOptions = merge({}, config, uischema.options);
  const { color = "primary", size } = appliedUiSchemaOptions;

  const actionDisabled =
    !connection || !enabled || working || !connection?.status?.online?.valid;

  const onClickHandler = useCallback(async () => {
    setWorking(true);

    const {
      action,
      actionOptions,
      actionCredentials,
      path: dataPath,
    } = appliedUiSchemaOptions;

    const { result } = await executeLookupCommand({
      command: action,
      connectionId: connection.id,
      credentials: actionCredentials,
      options: actionOptions,
    });

    handleChange(targetPath, dataPath ? get(result, dataPath) : result);
    setWorking(false);
  }, [
    handleChange,
    connection,
    appliedUiSchemaOptions,
    targetPath,
    executeLookupCommand,
  ]);

  if (!visible) {
    return null;
  }

  return (
    <DLXTooltip
      text={
        !connection?.status?.online?.valid
          ? `${connection?.name || "Connection"} is offline`
          : ""
      }
      placement="bottom"
    >
      <Button
        color={color}
        size={size}
        disabled={actionDisabled}
        onClick={onClickHandler}
        style={{ margin: "5px" }}
        variant="contained"
        endIcon={
          <FontAwesomeIcon
            icon={working ? "spinner" : "dot-circle"}
            spin={working}
          />
        }
      >
        {label || uischema.label}
      </Button>
    </DLXTooltip>
  );
};

const ConnectionActionTester = rankWith(110, or(uiTypeIs("DlxConnectionAction")));

export const ConnectionActionControl = {
  renderer: withJsonFormsControlProps(React.memo(ConnectionAction)),
  tester: ConnectionActionTester,
};

export const ConnectionActionCell = {
  cell: withJsonFormsCellProps(ConnectionAction),
  tester: rankWith(1000, uiTypeIs("DlxConnectionAction")),
};
