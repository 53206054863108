import CircularProgress from "@mui/material/CircularProgress";
import React from "react";
import Backdrop from "@mui/material/Backdrop";
import { withStyles } from "tss-react/mui";

export const LoadingIndicator = withStyles(
  ({ classes, ...props }) => {
    return (
      <div className={classes.root} {...props}>
        <CircularProgress />
      </div>
    );
  },
  {
    root: {
      alignItems: "center",
      display: "flex",
      flexDirection: "column",
      height: "100vh",
      justifyContent: "center",
      margin: "0 auto",
      textAlign: "center",
      width: "100%",
    },
  }
);

export default function LoadingScreen({ overlay }) {
  if (overlay) {
    return (
      <Backdrop open={true} style={{ zIndex: 1200 }}>
        <CircularProgress color="inherit" />
      </Backdrop>
    );
  }
  return <LoadingIndicator />;
}
