import React from "react";
import { optionIs, or, rankWith } from "@jsonforms/core";
import { withJsonFormsCellProps, withJsonFormsControlProps } from "@jsonforms/react";
import MuiSelectorControl from "./MuiSelectorControl";

const SelectorTester = rankWith(
  103, //increase rank as needed
  optionIs("selector", true)
);

const MuiSelector = {
  renderer: withJsonFormsControlProps(React.memo(MuiSelectorControl)),
  tester: SelectorTester,
};

export const MuiSelectorCell = {
  cell: withJsonFormsCellProps(React.memo(MuiSelectorControl)),
  tester: rankWith(
    100,
    or(optionIs("selections", true), optionIs("selector", true))
  ),
};

export default MuiSelector;
