import React from "react";
import Spinner from "../Spinner";

const PRIMARY = "bg-primary bg-hover-primary-dark text-contrast";
const SECONDARY = "bg-secondary bg-hover-secondary-dark text-hover-contrast";
const BORDER = "ba border-primary bg-hover-primary text-hover-contrast";
const ERROR = "bg-error bg-hover-primary-dark text-contrast";
const TEXT = "text-hover-primary";

const BUTTON_VARIANTS = Object.freeze({
  PRIMARY,
  SECONDARY,
  BORDER,
  ERROR,
  TEXT,
});

function Button({
  children,
  onClick = () => {},
  variant = "",
  className = "",
  dataTestId = "",
  disabled = false,
  tabIndex = 0,
  isLoading = false,
}) {
  let css = `rounded2 bn bg-none ${className} ${dataTestId}`;
  if (disabled) {
    css = `${css} bg-secondary bg-hover-secondary text-secondary`;
  } else {
    css = `${css} ${variant} pointer`;
  }
  if (isLoading) {
    css = `${css} ${variant} relative z0`;
  }

  return (
    <button
      onClick={!disabled ? onClick : null}
      className={css}
      style={{ font: "inherit", lineHeight: "120%" }}
      data-testid={dataTestId}
      tabIndex={tabIndex}
      disabled={isLoading}
    >
      {children}
      {isLoading && (
        <div
          className="absolute z0 top0 right0 bottom0 left0 rounded2"
          style={{ background: "inherit" }}
        >
          <Spinner
            className="absolute centerh centerv"
            size={20}
            style={{
              top: "50%",
              left: "50%",
              marginTop: "-10px",
              marginLeft: "-10px",
            }}
          />
        </div>
      )}
    </button>
  );
}

export default Button;
export { BUTTON_VARIANTS };
