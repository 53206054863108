import React from "react";
import LabelChip, { LABEL_CHIP_VARIANTS } from "@dlx/atoms/LabelChip";

export const LabelsListView = ({ list = [] }) => {
  return (
    <>
      {list &&
        list.map(({ name, value, scope, error }) => {
          if (scope) {
            return null;
          }
          const labelProps = {
            label: name,
            value,
            error,
            variant: LABEL_CHIP_VARIANTS.OUTLINED.PRIMARY,
            ...(error?.message && { tooltip: error.message }),
            dataTestId: name,
          };

          return <LabelChip className="mt2 ml2" key={name} {...labelProps} />;
        })}
    </>
  );
};
