import React from "react";
import { rankWith, formatIs } from "@jsonforms/core";
import { withJsonFormsCellProps, withJsonFormsControlProps } from "@jsonforms/react";
import Input from "@mui/material/Input";

const jsonSchemaFormat = (data) => {
  if (!data) {
    return "";
  }
  try {
    const parsedDate = Date.parse(data);
    return new Date(parsedDate).toISOString();
  } catch (e) {
    return data;
  }
};

const htmlInputFormat = (props) => {
  if (!props.data) {
    return "";
  }
  try {
    const parsedDate = Date.parse(props.data);
    const date = new Date(parsedDate).toISOString();
    const pattern = new RegExp(/\d{4}-\d{2}-\d{2}T\d{2}:\d{2}/);
    return date.match(pattern)[0];
  } catch (e) {
    return props.data;
  }
};
export const MaterialDateTimeControl = (props) => {
  return (
    <Input
      type="datetime-local"
      value={htmlInputFormat(props)}
      fullWidth
      onChange={(e) =>
        props.handleChange(props.path, jsonSchemaFormat(e.target.value))
      }
    />
  );
};

export const MaterialDateTimeRenderer = {
  renderer: withJsonFormsControlProps(MaterialDateTimeControl),
  tester: rankWith(3, formatIs("date-time")),
};

export const MaterialDateTimeCell = {
  cell: withJsonFormsCellProps(MaterialDateTimeControl),
  tester: rankWith(2, formatIs("date-time")),
};
