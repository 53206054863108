import { client } from "./api/client";
import {
  updateOrchestrationSchema,
  createOrchestrationSchema,
} from "../utils/schemas/orchestration";
import executions from "../state/executions/api";

const resources = {
  connectors: {
    get: ({ id }) => ({
      method: "get",
      url: `/connectors/${id}`,
    }),
  },
  connections: {
    get: ({ id }) => ({
      method: "get",
      url: `/connections/${id}`,
    }),
    list: (params) => {
      const query = Object.entries(params)
        .map((entry) => entry.join("="))
        .join("&");
      return {
        method: "get",
        url: `/connections` + (query ? "?" + query : ""),
      };
    },
  },
  orchestrations: {
    get: ({ id }) => ({
      method: "get",
      url: `/orchestrations/${id}`,
    }),
    list: (params) => {
      const query = Object.entries(params)
        .map((entry) => entry.join("="))
        .join("&");
      return {
        method: "get",
        url: `/orchestrations` + (query ? "?" + query : ""),
      };
    },
    create: () => ({
      method: "post",
      url: `/orchestrations`,
      schema: createOrchestrationSchema,
    }),
    update: ({ id }) => ({
      method: "put",
      url: `/orchestrations/${id}`,
      schema: updateOrchestrationSchema,
    }),
    destroy: ({ id }) => ({
      method: "delete",
      url: `/orchestrations/${id}`,
    }),
    lastExecution: ({ id }) => ({
      method: "get",
      url: `/orchestrations/${id}/last-execution`,
    }),
    lastRun: ({ id }) => ({
      method: "get",
      url: `/orchestrations/${id}/last-run`,
    }),
  },
  actors: {
    list: () => ({
      method: "get",
      url: "/actors",
    }),
  },
  executions,
};

export const request = ({ schema, ...config }, values) => {
  const data = schema ? schema.cast(values, { stripUnknown: true }) : values;
  if (data) {
    config.data = data;
  }
  return client(config);
};

function configure(apiResources) {
  const apiConfig = {};
  Object.entries(apiResources).forEach(([resource, methods]) => {
    apiConfig[resource] = {};
    Object.entries(methods).forEach(([method, requestConfig]) => {
      apiConfig[resource][method] = (values) =>
        request(requestConfig(values), values).then((res) => res.data);
    });
  });
  return apiConfig;
}

const api = configure(resources);

export default api;
