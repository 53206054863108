import { useEffect, useCallback } from "react";
import { getElectronicSignature } from "../../services/sso";
import { useSessionContext } from "../session/Provider";
import { openEsignDialogModal } from "./EsignDialogModal";
import { storeCurrentUser } from "@dlx/services/tokens";
import { useLocalStorage } from "react-use";

const challenges = [
  "urn:scitara-dlx:challenge:signature",
  "urn:scitara-dlx:challenge:reason-for-change",
];

function challengeExists(arr, challengesList) {
  return challengesList.some((challenge) => arr.includes(challenge));
}

function constructProps(
  requireReasonForChange,
  requireSignatures,
  currentUser,
  nonceVal,
  request,
  client
) {
  return {
    reasons: [],
    requireReasonForChange,
    requireSignatures,
    onSubmit: async (reason) => {
      if (requireSignatures) {
        const signature = await getElectronicSignature(currentUser?.email, nonceVal);
        request.headers["x-scitara-dlx-signature"] = signature;
      }
      if (requireReasonForChange) {
        request.headers["x-scitara-dlx-reason-for-change"] = reason;
      }
      return client(request);
    },
    key: request.data,
  };
}

/** @param {{client: import("axios").AxiosInstance}} props */
export const ESignRequestInterceptor = ({ client }) => {
  const [currentUser, setCurrentUser] = useLocalStorage("currentUser");
  const { iamToken } = useSessionContext();

  useEffect(() => {
    const fetchCurrentUser = async () => {
      if (!currentUser && iamToken) {
        const newUser = await storeCurrentUser(iamToken);
        setCurrentUser(newUser);
      }
    };
    fetchCurrentUser();
  }, [iamToken]);

  const getReasonsForChange = useCallback(
    async (request) => {
      const operations = {
        post: "create",
        put: "modify",
        delete: "delete",
      };
      const operation = operations[request.method];
      const res = await client.get(
        `/customers/${currentUser.customer}/reasons-for-change`,
        {
          params: { operation },
        }
      );
      return res.data.items;
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [currentUser]
  );

  useEffect(() => {
    const esignInterceptor = createEsignInterceptor(
      currentUser,
      client,
      getReasonsForChange
    );
    const interceptor = client.interceptors.response.use(null, esignInterceptor);
    return () => {
      client.interceptors.response.eject(interceptor);
    };
  }, [currentUser]);

  return null;
};

const createEsignInterceptor =
  (currentUser, client, getReasonsForChange) => async (error) => {
    const { data } = error.response;
    if (
      data.challenges &&
      challengeExists(Object.keys(data.challenges), challenges)
    ) {
      const challengeKeys = Object.keys(data.challenges);
      let nonceVal = "";
      let requireReasonForChange = false;
      let requireSignatures = false;
      const request = error.config;
      challengeKeys.forEach((challenge) => {
        if (challenge === challenges[0]) {
          nonceVal = data.challenges[challenge].nonce;
          requireSignatures = true;
        }
        if (challenge === challenges[1]) {
          requireReasonForChange = true;
        }
      });
      const props = constructProps(
        requireReasonForChange,
        requireSignatures,
        currentUser,
        nonceVal,
        request,
        client
      );
      if (requireReasonForChange) {
        props.reasons = await getReasonsForChange(request);
        if (!props.reasons?.length) {
          throw new Error("Reasons for change cannot be empty");
        }
      }
      return new Promise((resolve, reject) => {
        openEsignDialogModal({ ...props, resolve, reject });
      });
    }
    throw error;
  };
