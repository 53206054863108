import { createSlice } from "@reduxjs/toolkit";
import { initialState, reducer } from "../reducers/dbAdapter";

const tableName = "resource-policies";

const slice = createSlice({
  name: tableName,
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    reducer(tableName)(builder);
  },
});

export default slice.reducer;
