import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import FormControl from "@mui/material/FormControl";
import React, { useState } from "react";

import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import { useDispatch } from "react-redux";
import { alertError } from "@dlx/state/alerts/actions";
import { CANCEL } from "@dlx/state/constants";

function getTilteAndButtonText(requireSignatures, requireReasonForChange) {
  let title = "Signature Required";
  let buttonText = "Sign";
  if (requireSignatures && requireReasonForChange) {
    title = "Signature and Reasons for change Required";
  } else if (requireReasonForChange) {
    title = "Reasons for change Required";
    buttonText = "Continue";
  }
  return {
    title,
    buttonText,
  };
}

export const getBlankEsignModalProps = () => ({
  onSubmit: null,
  requireReasonForChange: null,
  requireSignatures: null,
  reasons: null,
  resolve: null,
  reject: null,
});

let esignDialogOpenHandler = null;

export const openEsignDialogModal = (props) => esignDialogOpenHandler(props);

export const EsignDialogModal = () => {
  const dispatch = useDispatch();
  const [open, setOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [reason, setReason] = useState();
  const [error, setError] = useState(false);
  const [properties, setProperties] = useState(getBlankEsignModalProps());

  const {
    onSubmit,
    requireReasonForChange,
    requireSignatures,
    reasons,
    resolve,
    reject,
  } = properties;
  esignDialogOpenHandler = (props) => {
    setReason(undefined);
    setError(false);
    setProperties(props);
    setOpen(true);
  };
  const { title, buttonText } = getTilteAndButtonText(
    requireSignatures,
    requireReasonForChange
  );
  const handleOnSubmit = async () => {
    if (!reason && requireReasonForChange) {
      setError("Reason for change is required");
      return;
    }
    setIsLoading(true);
    try {
      const response = await onSubmit(reason);
      resolve(response);
    } catch (err) {
      reject(err);
    }
    setIsLoading(false);
    setOpen(false);
    setProperties(getBlankEsignModalProps());
  };
  const handleOnCancel = () => {
    setIsLoading(false);
    dispatch({ type: CANCEL });
    const channel = new BroadcastChannel("dlx");
    channel.postMessage({ action: "cancel-esign" });
    channel.close();
    dispatch(alertError("Signature canceled"));
    setOpen(false);
  };

  return (
    <Dialog open={open}>
      <DialogTitle
        style={{
          border: "1px solid #dadce0",
          borderTopLeftRadius: "6px",
          borderTopRightRadius: "6px",
          backgroundColor: "#f6f6f6",
        }}
      >
        {title}
      </DialogTitle>
      <DialogContent>
        {requireReasonForChange ? (
          <ReasonsForChange
            requireSignatures={requireSignatures}
            reasons={reasons}
            setReason={setReason}
            error={error}
          />
        ) : (
          <Typography>
            Please verify your identity to continue with the operation.
          </Typography>
        )}
      </DialogContent>
      <DialogActions>
        <Button onClick={handleOnCancel}>Cancel</Button>
        <Button
          variant="contained"
          color="primary"
          disableElevation
          disableRipple
          disabled={isLoading}
          onClick={handleOnSubmit}
          style={{
            textTransform: "capitalize",
          }}
        >
          {buttonText}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

const ReasonsForChange = ({ requireSignatures, reasons, setReason, error }) => {
  let instructions = `Please specify the reason for change to continue with the operation`;
  if (requireSignatures) {
    instructions = `Please specify the reason for change and verify your identity to continue with the operation`;
  }
  return (
    <React.Fragment>
      <Typography marginTop={1}>{instructions}</Typography>
      <FormControl variant="standard" fullWidth>
        <Select fullWidth onChange={(e) => setReason(e.target.value)} error={error}>
          {reasons.map((r) => (
            <MenuItem key={r.id} value={r.text}>
              {r.text}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      {error && <div className="text-error font-small mt2">{error}</div>}
    </React.Fragment>
  );
};
