import React, { Fragment, useEffect, useState } from "react";
import InputSelect from "@dlx/atoms/InputSelect";
import { DeleteIcon } from "../../icons";
import { AstEditor, astToExpression, expressionToAst } from "../ExpressionEditor";
import Button, { BUTTON_VARIANTS } from "@dlx/atoms/Button";
import isEmpty from "lodash/isEmpty";

export const LabelsListEditor = ({
  list = [],
  context = {},
  errors = [],
  onChange,
  getLabelNames,
}) => {
  const [labels, setLabels] = useState([...list] || []);
  const [options, setOptions] = useState([]);

  useEffect(() => {
    const abortController = new AbortController();
    const signal = abortController.signal;
    const fetchLabels = async () => {
      try {
        const response = await getLabelNames({ signal });
        if (response.items) {
          setOptions(response.items);
        }
      } catch (error) {
        //Error handling process
      }
    };

    fetchLabels();

    return () => {
      abortController.abort();
    };
  }, [list]);

  const addField = () => {
    labels.push({ name: null });
    onChange([...labels]);
    setLabels(labels);
  };

  const removeField = (index) => {
    labels.splice(index, 1);
    onChange([...labels]);
    setLabels(labels);
  };

  const onTextChange = (value, index, labelType) => {
    const copy = { ...labels[index], [labelType]: value };
    if (labelType === "value" && value === undefined) {
      delete copy[labelType];
    }
    labels[index] = copy;
    onChange([...labels]);
    setLabels(labels);
  };

  const getOptions = () => {
    const selectedLabels = labels.map((l) => l.name) || [];
    return options?.filter((opt) => !selectedLabels.includes(opt));
  };

  return (
    <>
      {!labels.length && (
        <div className="font-default text-secondary items-center mb3 mt4 tc">
          No labels have been added
        </div>
      )}
      {labels.map((item, index) => {
        const tagError = errors.length && errors[index];
        return (
          <Fragment key={index}>
            <ListItem
              options={getOptions()}
              item={item}
              onTextChange={onTextChange}
              index={index}
              context={context}
              removeField={removeField}
              error={tagError?.name}
            />
          </Fragment>
        );
      })}
      <div className="tc">
        <Button
          className="pa2 text-primary mt2"
          variant={BUTTON_VARIANTS.BORDER}
          onClick={addField}
        >
          + Add Label
        </Button>
      </div>
    </>
  );
};

const ListItem = ({
  options,
  item,
  onTextChange,
  index,
  context,
  removeField,
  error,
}) => {
  const [displayError, setDisplayError] = useState(false);
  const [draft, setDraft] = useState(expressionToAst(item.value));

  useEffect(() => {
    const expression = astToExpression(draft);
    if (item.value && expression !== item.value) {
      setDraft(expressionToAst(item.value));
    }
  }, [item.value]);

  useEffect(() => {
    if (!isEmpty(draft)) {
      handleUpdateItem();
    }
  }, [draft]);

  const handleUpdateItem = async () => {
    const exp = astToExpression(draft);
    if (exp) {
      onTextChange(exp, index, "value");
    }
  };

  const handleClear = () => {
    setDraft({});
    onTextChange(undefined, index, "value");
  };

  return (
    <>
      <div className="flex items-center bg-secondary-light mt2 ph2 rounded2">
        <div className="w35">
          <InputSelect
            freeSolo={true}
            options={options}
            value={item?.name}
            onChange={(_, selected) => onTextChange(selected, index, "name")}
            onBlur={() => setDisplayError(true)}
            testId={`dtid_label_name_${index}`}
            placeholder="Label name"
            id="setting_label"
          />
        </div>
        <div className="flex-auto">
          <AstEditor
            element={draft}
            setElement={setDraft}
            onClear={handleClear}
            context={context}
            dataTestId={`dtid_label_value_${index}`}
          />
        </div>
        <span
          data-testid={`dtid_label_delete_${index}`}
          className="text-secondary text-hover-primary pointer ml2"
          onClick={() => removeField(index)}
        >
          <DeleteIcon size={24} color="primary" />
        </span>
      </div>
      {error && displayError && (
        <span className="ml4 text-error font-small">
          Label name cannot be empty!
        </span>
      )}
    </>
  );
};
