import React, { useEffect, useRef } from "react";
import DLXTooltip from "@dlx/components/DLXTooltip/DLXTooltip";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { IconButton } from "@mui/material";
import { useScitaraHelp } from "@dlx/components/ScitaraHelp";

export const ScitaraHelpButton = () => {
  const { launchKO, status, isEnabled, openLink } = useScitaraHelp();
  const buttonRef = useRef(null);
  let title = status === "loading" ? "Loading..." : "Scitara Help Center";
  if (status === "error") {
    title = "Please try again later";
  }
  const isDisabled = (isEnabled && status === "loading") || status === "error";
  useEffect(() => {
    let handler;
    if (isEnabled) {
      const button = buttonRef.current;
      button.addEventListener("click", launchKO, { once: true });
      handler = () => {
        button.removeEventListener("click", launchKO);
      };
    }
    return handler;
  }, [isEnabled, launchKO]);

  return (
    <DLXTooltip placement="bottom" text={title} className="">
      <span data-testid="help-button">
        <IconButton
          id="ko-launch-button"
          style={{
            textDecoration: "none",
            color: "#6f6f6f",
            fontSize: "2rem",
          }}
          ref={buttonRef}
          disabled={isDisabled}
          onClick={() => (isEnabled ? null : openLink())}
        >
          <FontAwesomeIcon icon="question-circle" />
        </IconButton>
      </span>
    </DLXTooltip>
  );
};
