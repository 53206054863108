import React, { useState } from "react";
import styles from "./ConnectionInterfaceTable.module.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import IconButton from "@mui/material/IconButton";
import MemoModal from "../../MemoModal";
import DLXTooltip from "../../DLXTooltip/DLXTooltip";

const Memo = ({ recordMemo = "optional", interfaceData, updateInterfaceData }) => {
  const [showMemoModal, setShowMemoModal] = useState(false);
  if (recordMemo === "none") {
    return null;
  }

  return (
    <tr>
      <td>Memo</td>
      <td>
        <div className={styles.rightCell}>
          {interfaceData.memo ? (
            <div
              className={styles.memo}
              dangerouslySetInnerHTML={{
                __html: interfaceData.memo,
              }}
            ></div>
          ) : (
            `A memo ${recordMemo}`
          )}
          <div className={styles.buttonContainer}>
            <DLXTooltip text={interfaceData.memo ? "Edit memo" : "Add memo"}>
              <IconButton
                size="small"
                variant="contained"
                onClick={() => setShowMemoModal(true)}
              >
                <FontAwesomeIcon icon="edit" />
              </IconButton>
            </DLXTooltip>
            {interfaceData.memo && (
              <DLXTooltip text="Delete memo">
                <IconButton
                  className={styles.iconButton}
                  size="small"
                  variant="contained"
                  onClick={() => updateInterfaceData({ memo: undefined })}
                >
                  <FontAwesomeIcon icon="trash" />
                </IconButton>
              </DLXTooltip>
            )}
            <MemoModal
              memo={interfaceData.memo}
              onSubmit={(memo) => {
                updateInterfaceData({ memo });
                setShowMemoModal(false);
              }}
              onCancel={() => setShowMemoModal(false)}
              visible={showMemoModal}
            />
          </div>
        </div>
      </td>
    </tr>
  );
};

export default Memo;
