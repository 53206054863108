import React, { useMemo } from "react";
import colors from "./assets/styles/variables.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import TouchAppRoundedIcon from "@mui/icons-material/TouchAppRounded";
import TimerTypeIcon from "@mui/icons-material/Timer";
import PlaylistAddCheckIcon from "@mui/icons-material/PlaylistAddCheck";

import { Error as ErrorIconMUI, GetApp } from "@mui/icons-material";
import { faCog } from "@fortawesome/free-solid-svg-icons";

const MUIIcon = (props) => {
  const { icon: Icon, color, size } = props;
  return <Icon style={{ color: color, fontSize: size }} />;
};

const FAIcon = (props) => {
  const { icon, size } = props;
  const spacing = `${size}px`;
  return <FontAwesomeIcon icon={icon} style={{ height: spacing, width: spacing }} />;
};

export const ErrorIcon = (props) => {
  return <MUIIcon {...props} icon={ErrorIconMUI} />;
};

export const ExclamationIconSVG = (props) => {
  const { size = 24 } = props;
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
      height={size}
      width={size}
      fill="none"
      stroke="currentColor"
      stroke-width="4"
      stroke-linecap="round"
      stroke-linejoin="round"
      {...props}
    >
      <line x1="12" y1="0" x2="12" y2="12" />
      <line x1="12" y1="20" x2="12" y2="24" />
    </svg>
  );
};

export const ExportPdf = (props) => {
  const { size } = props;

  return (
    <svg
      height={size}
      width={size}
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="10" cy="10" r="10" fill="white" />
      <path
        d="M5.33333 16C4.96667 16 4.65267 15.8696 4.39133 15.6087C4.13044 15.3473 4
        15.0333 4 14.6667V6.33333C4 6.16667 4.02778 6.01667 4.08333 5.88333C4.13889
         5.75 4.21111 5.62222 4.3 5.5L5.23333 4.36667C5.32222 4.24444 5.43333 4.15267
          5.56667 4.09133C5.7 4.03044 5.84444 4 6 4H14C14.1556 4 14.3 4.03044 14.4333
          4.09133C14.5667 4.15267 14.6778 4.24444 14.7667 4.36667L15.7 5.5C15.7889 5.62222
          15.8611 5.75 15.9167 5.88333C15.9722 6.01667 16 6.16667 16 6.33333V14.6667C16
          15.0333 15.8696 15.3473 15.6087 15.6087C15.3473 15.8696 15.0333 16 14.6667
          16H5.33333ZM5.6 6H14.4L13.8333 5.33333H6.16667L5.6 6ZM10 13.7167C10.0889
          13.7167 10.1722 13.7027 10.25 13.6747C10.3278 13.6471 10.4 13.6 10.4667
          13.5333L12.2 11.8C12.3222 11.6778 12.3833 11.5222 12.3833 11.3333C12.3833
          11.1444 12.3222 10.9889 12.2 10.8667C12.0778 10.7444 11.9222 10.6833 11.7333
          10.6833C11.5444 10.6833 11.3889 10.7444 11.2667 10.8667L10.6667 11.4667V9.33333C10.6667
          9.14444 10.6029 8.986 10.4753 8.858C10.3473 8.73044 10.1889 8.66667 10 8.66667C9.81111
          8.66667 9.65289 8.73044 9.52533 8.858C9.39733 8.986 9.33333 9.14444 9.33333
          9.33333V11.4667L8.73333 10.8667C8.61111 10.7444 8.45556 10.6833 8.26667
          10.6833C8.07778 10.6833 7.92222 10.7444 7.8 10.8667C7.67778 10.9889 7.61667
          11.1444 7.61667 11.3333C7.61667 11.5222 7.67778 11.6778 7.8 11.8L9.53333
          13.5333C9.6 13.6 9.67222 13.6471 9.75 13.6747C9.82778 13.7027 9.91111 13.7167 10 13.7167Z"
        fill="currentColor"
      />
    </svg>
  );
};

//Expression editor icons
export const ReferenceIcon = (props) => {
  const { size } = props;
  return (
    <svg
      height={size}
      width={size}
      viewBox="0 0 20 20"
      fill="currentColor"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M3.56388 9.50446C3.78791 8.32831 4.89205 7.5202 6.09221 7.5202H8.43652C8.85258 7.5202 9.19662 7.17616 9.19662
         6.7601C9.19662 6.34405 8.85258 6 8.43652 6H6.17222C4.08395 6 2.2197 7.5282 2.01967 9.60848C1.96436 10.1639 2.02601
         10.7248 2.20067 11.255C2.37532 11.7852 2.6591 12.2729 3.03373 12.6867C3.40835 13.1005 3.86551 13.4313 4.37575
         13.6577C4.88599 13.884 5.438 14.001 5.9962 14.0011H8.43652C8.85258 14.0011 9.19662 13.657 9.19662 13.241C9.19662
         12.8249 8.85258 12.4809 8.43652 12.4809H5.9962C5.62869 12.4797 5.26599 12.3972 4.93417 12.2392C4.60235
         12.0812 4.30964 11.8517 4.07709 11.5671C3.84453 11.2825 3.6779 10.95 3.58916 10.5933C3.50043 10.2367 3.49179
         9.86484 3.56388 9.50446ZM7.59641 10.8006H12.397C12.8371 10.8006 13.1972 10.4406 13.1972 10.0005C13.1972
         9.56047 12.8371 9.20042 12.397 9.20042H7.59641C7.15635 9.20042 6.79631 9.56047 6.79631 10.0005C6.79631 10.4406
         7.15635 10.8006 7.59641 10.8006ZM13.8212 6H11.5569C11.1409 6 10.7968 6.34405 10.7968 6.7601C10.7968 7.17616
         11.1409 7.5202 11.5569 7.5202H13.9012C15.1014 7.5202 16.2056 8.32831 16.4296 9.50446C16.5017 9.86484 16.493
         10.2367 16.4043 10.5933C16.3156 10.95 16.1489 11.2825 15.9164 11.5671C15.6838 11.8517 15.3911 12.0812 15.0593
         12.2392C14.7275 12.3972 14.3648 12.4797 13.9973 12.4809H11.5569C11.1409 12.4809 10.7968 12.8249 10.7968
         13.241C10.7968 13.657 11.1409 14.0011 11.5569 14.0011H13.9973C16.3336 14.0011 18.2058 11.9928 17.9818
         9.60848C17.7818 7.5282 15.9095 6 13.8212 6Z"
        fill="currentColor"
      />
    </svg>
  );
};

export const ArrayIcon = (props) => {
  const { size } = props;
  return (
    <svg
      height={size}
      width={size}
      viewBox="0 0 20 20"
      fill="currentColor"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M14.5 14.5V5.5H13C12.7875 5.5 12.6095 5.42825 12.466 5.28475C12.322 5.14075 12.25 4.9625 12.25 4.75C12.25
         4.5375 12.322 4.35925 12.466 4.21525C12.6095 4.07175 12.7875 4 13 4H14.5C14.9125 4 15.2657 4.147 15.5597
         4.441C15.8533 4.7345 16 5.0875 16 5.5V14.5C16 14.9125 15.8533 15.2657 15.5597 15.5597C15.2657 15.8533 14.9125
         16 14.5 16H13C12.7875 16 12.6095 15.928 12.466 15.784C12.322 15.6405 12.25 15.4625 12.25 15.25C12.25 15.0375
         12.322 14.8595 12.466 14.716C12.6095 14.572 12.7875 14.5 13 14.5H14.5ZM5.5 14.5H7C7.2125 14.5 7.39075 14.572
         7.53475 14.716C7.67825 14.8595 7.75 15.0375 7.75 15.25C7.75 15.4625 7.67825 15.6405 7.53475 15.784C7.39075
         15.928 7.2125 16 7 16H5.5C5.0875 16 4.7345 15.8533 4.441 15.5597C4.147 15.2657 4 14.9125 4 14.5V5.5C4 5.0875
         4.147 4.7345 4.441 4.441C4.7345 4.147 5.0875 4 5.5 4H7C7.2125 4 7.39075 4.07175 7.53475 4.21525C7.67825 4.35925
         7.75 4.5375 7.75 4.75C7.75 4.9625 7.67825 5.14075 7.53475 5.28475C7.39075 5.42825 7.2125 5.5 7 5.5H5.5V14.5Z"
        fill="currentColor"
      />
    </svg>
  );
};

export const TextIcon = (props) => {
  const { size } = props;

  return (
    <svg
      height={size}
      width={size}
      viewBox="0 0 20 20"
      fill="currentColor"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M7.9 14.6C7.65 14.6 7.4376 14.5124 7.2628 14.3372C7.0876 14.1624 7 13.95 7 13.7V6.8H4.9C4.65 6.8 4.4376
         6.7124 4.2628 6.5372C4.0876 6.3624 4 6.15 4 5.9C4 5.65 4.0876 5.4376 4.2628 5.2628C4.4376 5.0876 4.65 5 4.9
         5H10.9C11.15 5 11.3624 5.0876 11.5372 5.2628C11.7124 5.4376 11.8 5.65 11.8 5.9C11.8 6.15 11.7124 6.3624 11.5372
         6.5372C11.3624 6.7124 11.15 6.8 10.9 6.8H8.8V13.7C8.8 13.95 8.7124 14.1624 8.5372 14.3372C8.3624 14.5124 8.15
         14.6 7.9 14.6ZM13.3 14.6C13.05 14.6 12.8376 14.5124 12.6628 14.3372C12.4876 14.1624 12.4 13.95 12.4
         13.7V9.8H11.5C11.25 9.8 11.0376 9.7124 10.8628 9.5372C10.6876 9.3624 10.6 9.15 10.6 8.9C10.6 8.65 10.6876 8.4376
         10.8628 8.2628C11.0376 8.0876 11.25 8 11.5 8H15.1C15.35 8 15.5624 8.0876 15.7372 8.2628C15.9124 8.4376 16 8.65
         16 8.9C16 9.15 15.9124 9.3624 15.7372 9.5372C15.5624 9.7124 15.35 9.8 15.1 9.8H14.2V13.7C14.2 13.95 14.1124
         14.1624 13.9372 14.3372C13.7624 14.5124 13.55 14.6 13.3 14.6Z"
        fill="currentColor"
      />
    </svg>
  );
};

export const NumberIcon = (props) => {
  const { size } = props;
  return (
    <svg
      height={size}
      width={size}
      viewBox="0 0 20 20"
      fill="currentColor"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M8.78514 5.01799C8.93546 5.03682 9.07216 5.11458 9.16517 5.23416C9.25818 5.35375 9.29989 5.50537 9.28114
         5.6557L9.00457 7.87056H11.2811L11.576 5.51399C11.5837 5.4382 11.6064 5.3647 11.6429 5.29782C11.6793 5.23094
         11.7288 5.17203 11.7884 5.12455C11.848 5.07708 11.9164 5.04199 11.9898 5.02136C12.0631 5.00072 12.1398 4.99496
         12.2154 5.00441C12.291 5.01386 12.3639 5.03833 12.4299 5.07638C12.4959 5.11443 12.5537 5.16529 12.5997
         5.22597C12.6458 5.28665 12.6792 5.35593 12.6981 5.42973C12.717 5.50353 12.7209 5.58036 12.7097 5.6557L12.4331
         7.87056H14.4286C14.5801 7.87056 14.7255 7.93076 14.8326 8.03793C14.9398 8.14509 15 8.29044 15 8.44199C15 8.59354
         14.9398 8.73889 14.8326 8.84605C14.7255 8.95321 14.5801 9.01342 14.4286 9.01342H12.2903L12.0046
         11.2991H14.1429C14.2944 11.2991 14.4398 11.3593 14.5469 11.4665C14.6541 11.5737 14.7143 11.719 14.7143
         11.8706C14.7143 12.0221 14.6541 12.1675 14.5469 12.2746C14.4398 12.3818 14.2944 12.442 14.1429
         12.442H11.8617L11.5669 14.7986C11.5592 14.8744 11.5365 14.9479 11.5 15.0147C11.4635 15.0816 11.414 15.1405 11.3545
         15.188C11.2949 15.2355 11.2264 15.2706 11.1531 15.2912C11.0798 15.3118 11.003 15.3176 10.9274 15.3081C10.8519
         15.2987 10.7789 15.2742 10.7129 15.2362C10.6469 15.1981 10.5892 15.1473 10.5431 15.0866C10.4971 15.0259 10.4636
         14.9566 10.4447 14.8828C10.4259 14.809 10.4219 14.7322 10.4331 14.6568L10.7097 12.442H8.43314L8.13829
         14.7986C8.13063 14.8744 8.10789 14.9479 8.07142 15.0147C8.03494 15.0816 7.98546 15.1405 7.92589 15.188C7.86631
         15.2355 7.79785 15.2706 7.72451 15.2912C7.65118 15.3118 7.57447 15.3176 7.49887 15.3081C7.42328 15.2987 7.35034
         15.2742 7.28435 15.2362C7.21835 15.1981 7.16063 15.1473 7.11457 15.0866C7.06852 15.0259 7.03506 14.9566 7.01617
         14.8828C6.99728 14.809 6.99334 14.7322 7.00457 14.6568L7.28114 12.442H5.57143C5.41988 12.442 5.27453 12.3818
         5.16737 12.2746C5.0602 12.1675 5 12.0221 5 11.8706C5 11.719 5.0602 11.5737 5.16737 11.4665C5.27453 11.3593
         5.41988 11.2991 5.57143 11.2991H7.424L7.70971 9.01342H5.85714C5.70559 9.01342 5.56025 8.95321 5.45308
         8.84605C5.34592 8.73889 5.28571 8.59354 5.28571 8.44199C5.28571 8.29044 5.34592 8.14509 5.45308 8.03793C5.56025
         7.93076 5.70559 7.87056 5.85714 7.87056H7.85257L8.14743 5.51399C8.16626 5.36367 8.24402 5.22698 8.3636
         5.13397C8.48319 5.04096 8.63481 4.99924 8.78514 5.01799ZM10.8526 11.2991L11.1383 9.01342H8.86171L8.576
         11.2991H10.8526Z"
        fill="currentColor"
      />
    </svg>
  );
};

export const ToggleIcon = (props) => {
  const { size } = props;
  return (
    <svg
      height={size}
      width={size}
      viewBox="0 0 20 20"
      fill="currentColor"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M13 7H7C6.20435 7 5.44129 7.31607 4.87868 7.87868C4.31607 8.44129 4 9.20435 4 10C4 10.7956 4.31607 11.5587
         4.87868 12.1213C5.44129 12.6839 6.20435 13 7 13H13C13.7956 13 14.5587 12.6839 15.1213 12.1213C15.6839 11.5587
         16 10.7956 16 10C16 9.20435 15.6839 8.44129 15.1213 7.87868C14.5587 7.31607 13.7956 7 13 7ZM13 11.8C12.5226
         11.8 12.0648 11.6104 11.7272 11.2728C11.3896 10.9352 11.2 10.4774 11.2 10C11.2 9.52261 11.3896 9.06477 11.7272
         8.72721C12.0648 8.38964 12.5226 8.2 13 8.2C13.4774 8.2 13.9352 8.38964 14.2728 8.72721C14.6104 9.06477 14.8 9.52261
         14.8 10C14.8 10.4774 14.6104 10.9352 14.2728 11.2728C13.9352 11.6104 13.4774 11.8 13 11.8Z"
        fill="currentColor"
      />
    </svg>
  );
};

export const ObjectIcon = (props) => {
  const { size } = props;
  return (
    <svg
      height={size}
      width={size}
      viewBox="0 0 20 20"
      fill="currentColor"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M6.45806 9.49C6.38435 9.67411 6.29164 9.84555 6.18226 10C6.29164 10.1544 6.38435 10.3259 6.45806 10.51C6.70968
         11.152 6.70968 11.908 6.70968 12.64C6.70968 14.194 6.79677 14.56 7.67742 14.56C7.83142 14.56 7.97911 14.6359
         8.088 14.7709C8.19689 14.9059 8.25806 15.089 8.25806 15.28C8.25806 15.471 8.19689 15.6541 8.088 15.7891C7.97911
         15.9241 7.83142 16 7.67742 16C6.75323 16 6.11935 15.586 5.8 14.77C5.54839 14.128 5.54839 13.372 5.54839
         12.64C5.54839 11.086 5.46129 10.72 4.58065 10.72C4.42665 10.72 4.27896 10.6441 4.17007 10.5091C4.06117 10.3741
         4 10.191 4 10C4 9.80904 4.06117 9.62591 4.17007 9.49088C4.27896 9.35586 4.42665 9.28 4.58065 9.28C5.46129 9.28
         5.54839 8.914 5.54839 7.36C5.54839 6.628 5.54839 5.872 5.8 5.23C6.11935 4.414 6.75323 4 7.67742 4C7.83142
         4 7.97911 4.07586 8.088 4.21088C8.19689 4.34591 8.25806 4.52904 8.25806 4.72C8.25806 4.91096 8.19689 5.09409
         8.088 5.22912C7.97911 5.36414 7.83142 5.44 7.67742 5.44C6.79677 5.44 6.70968 5.806 6.70968 7.36C6.70968 8.092
         6.70968 8.848 6.45806 9.49ZM15.4194 9.28C14.5387 9.28 14.4516 8.914 14.4516 7.36C14.4516 6.628 14.4516 5.872
         14.2 5.23C13.8806 4.414 13.2468 4 12.3226 4C12.1686 4 12.0209 4.07586 11.912 4.21088C11.8031 4.34591 11.7419
         4.52904 11.7419 4.72C11.7419 4.91096 11.8031 5.09409 11.912 5.22912C12.0209 5.36414 12.1686 5.44 12.3226
         5.44C13.2032 5.44 13.2903 5.806 13.2903 7.36C13.2903 8.092 13.2903 8.848 13.5419 9.49C13.6156 9.67411 13.7084
         9.84555 13.8177 10C13.7084 10.1544 13.6156 10.3259 13.5419 10.51C13.2903 11.152 13.2903 11.908 13.2903
         12.64C13.2903 14.194 13.2032 14.56 12.3226 14.56C12.1686 14.56 12.0209 14.6359 11.912 14.7709C11.8031 14.9059
         11.7419 15.089 11.7419 15.28C11.7419 15.471 11.8031 15.6541 11.912 15.7891C12.0209 15.9241 12.1686 16 12.3226
         16C13.2468 16 13.8806 15.586 14.2 14.77C14.4516 14.128 14.4516 13.372 14.4516 12.64C14.4516 11.086 14.5387 10.72
         15.4194 10.72C15.5734 10.72 15.721 10.6441 15.8299 10.5091C15.9388 10.3741 16 10.191 16 10C16 9.80904 15.9388
         9.62591 15.8299 9.49088C15.721 9.35586 15.5734 9.28 15.4194 9.28Z"
        fill="currentColor"
      />
    </svg>
  );
};

export const ClearIcon = (props) => {
  const { size } = props;
  return (
    <svg
      height={size}
      width={size}
      viewBox="0 0 20 20"
      fill="currentColor"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M7.43116 6.24555C7.27394 6.08833 7.0607 6 6.83836 6C6.61601 6 6.40277 6.08833 6.24555 6.24555C6.08833 6.40277
         6 6.61601 6 6.83836C6 7.0607 6.08833 7.27394 6.24555 7.43117L8.81438 10L6.24555 12.5688C6.08833 12.7261 6 12.9393
         6 13.1616C6 13.384 6.08833 13.5972 6.24555 13.7545C6.40277 13.9117 6.61601 14 6.83836 14C7.0607 14 7.27394 13.9117
         7.43116 13.7545L10 11.1856L12.5688 13.7545C12.7261 13.9117 12.9393 14 13.1616 14C13.384 14 13.5972 13.9117 13.7545
         13.7545C13.9117 13.5972 14 13.384 14 13.1616C14 12.9393 13.9117 12.7261 13.7545 12.5688L11.1856 10L13.7545
         7.43116C13.9117 7.27394 14 7.0607 14 6.83836C14 6.61601 13.9117 6.40277 13.7545 6.24555C13.5972 6.08833 13.384
         6 13.1616 6C12.9393 6 12.7261 6.08833 12.5688 6.24555L10 8.81438L7.43116 6.24555Z"
        fill="currentColor"
      />
    </svg>
  );
};

export const RunIcon = (props) => {
  const { size } = props;
  return (
    <svg
      height={size}
      width={size}
      viewBox="0 0 24 24"
      fill="currentColor"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M13.8543 6.6444C14.6835 6.6444 15.3618 5.95941 15.3618 5.1222C15.3618 4.28499 14.6835 3.6 13.8543 3.6C13.0252
         3.6 12.3468 4.28499 12.3468 5.1222C12.3468 5.95941 13.0252 6.6444 13.8543 6.6444ZM11.4649 15.7776L11.8946
         13.8748L13.4774 15.397V19.2025C13.4774 19.6211 13.8166 19.9636 14.2312 19.9636C14.6458 19.9636 14.985 19.6211
         14.985 19.2025V14.9099C14.985 14.4913 14.8191 14.0956 14.5176 13.8063L13.4021 12.7332L13.8543 10.4499C14.7038
         11.4304 15.8666 12.0794 17.1407 12.2841C17.593 12.3526 18 11.9873 18 11.523C18 11.1501 17.7286 10.8381 17.3593
         10.7772C16.2136 10.5869 15.2639 9.9019 14.7588 9.00381L14.0051 7.78605C13.7036 7.32939 13.2513 7.02495 12.7237
         7.02495C12.4976 7.02495 12.3468 7.10106 12.1207 7.10106L9.12072 8.3797C8.84765 8.49756 8.61489 8.69386 8.4514
         8.9442C8.2879 9.19453 8.20088 9.48787 8.20114 9.78774V11.5915C8.20114 12.0101 8.54033 12.3526 8.9549
         12.3526C9.36946 12.3526 9.70865 12.0101 9.70865 11.5915V9.7649L11.0654 9.23214L9.85941 15.397L6.90467
         14.7882C6.49764 14.7044 6.09815 14.9708 6.01524 15.3818V15.4123C5.93232 15.8233 6.19614 16.2266 6.60317
         16.3104L9.70112 16.9345C10.0864 17.0117 10.4864 16.934 10.8158 16.7179C11.1453 16.5018 11.3781 16.1645 11.4649 15.7776Z"
        fill="currentColor"
      />
    </svg>
  );
};

export const EllipsisIcon = (props) => {
  const { size } = props;
  return (
    <svg
      height={size}
      width={size}
      viewBox="0 0 20 20"
      fill="currentColor"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M4 10C4 11.1046 3.10457 12 2 12C0.89543 12 0 11.1046 0 10C0 8.89543 0.89543 8 2 8C3.10457 8 4 8.89543 4 10Z"
        fill="currentColor"
      />
      <path
        d="M12 10C12 11.1046 11.1046 12 10 12C8.89543 12 8 11.1046 8 10C8 8.89543 8.89543 8 10 8C11.1046 8 12 8.89543 12 10Z"
        fill="currentColor"
      />
      <path
        d="M20 10C20 11.1046 19.1046 12 18 12C16.8954 12 16 11.1046 16 10C16 8.89543 16.8954 8 18 8C19.1046 8 20 8.89543 20 10Z"
        fill="currentColor"
      />
    </svg>
  );
};

export const TransformIcon = (props) => {
  const { size } = props;
  return (
    <svg
      height={size}
      width={size}
      viewBox="0 0 20 20"
      fill="currentColor"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M15.4 12.88H8.2C7.87 12.88 7.6 12.61 7.6 12.28V5.68H8.074C8.344 5.68 8.476 5.356 8.284 5.17L7.21 4.09C7.09
         3.97 6.904 3.97 6.784 4.09L5.71 5.17C5.66854 5.2123 5.64052 5.26592 5.62949 5.32411C5.61846 5.38231 5.62489
         5.44246 5.64799 5.497C5.67109 5.55154 5.70982 5.59802 5.75929 5.63059C5.80877 5.66316 5.86677 5.68035 5.926
         5.68H6.4V6.88H4.6C4.27 6.88 4 7.15 4 7.48C4 7.81 4.27 8.08 4.6 8.08H6.4V12.88C6.4 13.54 6.94 14.08 7.6
         14.08H12.4V15.28H11.926C11.656 15.28 11.524 15.604 11.716 15.79L12.79 16.864C12.91 16.984 13.096 16.984 13.216
         16.864L14.29 15.79C14.482 15.604 14.344 15.28 14.08 15.28H13.6V14.08H15.4C15.73 14.08 16 13.81 16 13.48C16 13.15
         15.73 12.88 15.4 12.88ZM12.4 11.68H13.6V8.08C13.6 7.42 13.06 6.88 12.4 6.88H8.8V8.08H11.8C12.13 8.08 12.4 8.35
         12.4 8.68V11.68Z"
        fill="currentColor"
      />
    </svg>
  );
};

export const DeleteIcon = (props) => {
  const { size } = props;
  return (
    <svg
      height={size}
      width={size}
      viewBox="0 0 20 20"
      fill="currentColor"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M15.033 8.40021L14.4044 9.48769L6.77947 5.08748L7.40807 4L9.31902 5.10005L10.1739 4.86747L12.8958
         6.43897L13.1283 7.30016L15.033 8.40021ZM6 14.7428V7.19958H9.18701L13.5432 9.71399V14.7428C13.5432 15.0762
         13.4108 15.396 13.175 15.6318C12.9392 15.8675 12.6194 16 12.286 16H7.2572C6.92377 16 6.604 15.8675 6.36823
         15.6318C6.13245 15.396 6 15.0762 6 14.7428Z"
        fill="currentColor"
      />
    </svg>
  );
};

export const TreeIcon = (props) => {
  const { size } = props;
  return (
    <svg
      height={size}
      width={size}
      viewBox="0 0 20 20"
      fill="currentColor"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M10.8462 8.04348V7.3913H10.1538C9.90903 7.3913 9.67424 7.48292 9.50113 7.64599C9.32802 7.80907 9.23077
         8.03025 9.23077 8.26087V11.7391C9.23077 11.9698 9.32802 12.1909 9.50113 12.354C9.67424 12.5171 9.90903 12.6087
         10.1538 12.6087H10.8462V11.9565C10.8462 11.7259 10.9434 11.5047 11.1165 11.3416C11.2896 11.1786 11.5244 11.087
         11.7692 11.087H14.0769C14.3217 11.087 14.5565 11.1786 14.7296 11.3416C14.9027 11.5047 15 11.7259 15
         11.9565V14.1304C15 14.3611 14.9027 14.5822 14.7296 14.7453C14.5565 14.9084 14.3217 15 14.0769 15H11.7692C11.5244
         15 11.2896 14.9084 11.1165 14.7453C10.9434 14.5822 10.8462 14.3611 10.8462 14.1304V13.4783H10.1538C9.66468
         13.4768 9.19599 13.2931 8.8501 12.9673C8.50421 12.6415 8.30921 12.1999 8.30769 11.7391V10.4348H6.69231V10.8696C6.69231
         11.1002 6.59506 11.3214 6.42194 11.4844C6.24883 11.6475 6.01405 11.7391 5.76923 11.7391H3.92308C3.67826 11.7391
         3.44347 11.6475 3.27036 11.4844C3.09725 11.3214 3 11.1002 3 10.8696V9.13043C3 8.89981 3.09725 8.67863 3.27036
         8.51556C3.44347 8.35248 3.67826 8.26087 3.92308 8.26087H5.76923C6.01405 8.26087 6.24883 8.35248 6.42194
         8.51556C6.59506 8.67863 6.69231 8.89981 6.69231 9.13043V9.56522H8.30769V8.26087C8.30921 7.80006 8.50421 7.35854
         8.8501 7.0327C9.19599 6.70686 9.66468 6.52317 10.1538 6.52174H10.8462V5.86957C10.8462 5.63894 10.9434 5.41776
         11.1165 5.25469C11.2896 5.09161 11.5244 5 11.7692 5H14.0769C14.3217 5 14.5565 5.09161 14.7296 5.25469C14.9027 5.41776
         15 5.63894 15 5.86957V8.04348C15 8.2741 14.9027 8.49528 14.7296 8.65835C14.5565 8.82143 14.3217 8.91304 14.0769
         8.91304H11.7692C11.5244 8.91304 11.2896 8.82143 11.1165 8.65835C10.9434 8.49528 10.8462 8.2741 10.8462 8.04348Z"
        fill="currentColor"
      />
    </svg>
  );
};

export const EditIcon = (props) => {
  const { size, ...rest } = props;
  return (
    <svg
      height={size}
      width={size}
      viewBox="5 5 10 10"
      fill="currentColor"
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
    >
      <path
        d="M9.94828 7.13425L10.931 8.09811L6.37931 12.642V13.6231H7.34483L11.9138 9.06196L12.8966 10.0258L8.32759
           14.5869C8.20115 14.7131 8.05471 14.8137 7.88828 14.8885C7.72138 14.9628 7.54598 15 7.36207 15H5.68966C5.49425
           15 5.33057 14.9341 5.19862 14.8024C5.06621 14.6702 5 14.5066 5 14.3115V12.642C5 12.4584 5.03448 12.2833 5.10345
           12.1167C5.17241 11.9505 5.27011 11.8044 5.39655 11.6781L9.94828 7.13425ZM12.8966 10.0258L9.94828 7.13425L11.6724
           5.41308C11.9483 5.13769 12.2789 5 12.6641 5C13.049 5 13.3736 5.13769 13.6379 5.41308L14.6034 6.39415C14.8678
           6.65806 15 6.97935 15 7.358C15 7.73666 14.8678 8.05795 14.6034 8.32186L12.8966 10.0258Z"
        fill="currentColor"
      />
    </svg>
  );
};

export const SelectPropertyIcon = (props) => {
  return <MUIIcon {...props} icon={GetApp} />;
};

export const AsterisksIcon = (props) => (
  <FontAwesomeIcon icon="asterisk" {...props} />
);

export const ExportIcon = (props) => (
  <FontAwesomeIcon icon="file-export" {...props} />
);

export const ImportIcon = (props) => (
  <FontAwesomeIcon icon="file-import" {...props} />
);

export const DownloadIcon = (props) => (
  <FontAwesomeIcon icon="download" {...props} />
);

export const BarsIcon = (props) => <FontAwesomeIcon icon="bars" {...props} />;
export const SortDownIcon = (props) => (
  <FontAwesomeIcon icon="sort-down" {...props} />
);

export const UserAltIcon = (props) => <FontAwesomeIcon icon="user-alt" {...props} />;

export const UserRoleIcon = ({ ...props }) => (
  <FontAwesomeIcon icon="user-graduate" {...props} />
);

export const UserGroupIcon = ({ ...props }) => (
  <FontAwesomeIcon icon="users" {...props} />
);

export const SignOutIcon = ({ color, rotation, ...props }) => (
  <FontAwesomeIcon
    icon="sign-out-alt"
    {...props}
    color={color}
    rotation={rotation}
  />
);

export const CloudIcon = (props) => <FontAwesomeIcon icon="cloud" {...props} />;

export const EnvelopeIcon = (props) => (
  <FontAwesomeIcon icon="envelope" {...props} />
);

export const Status = ({ status, ...props }) => (
  <FontAwesomeIcon icon={status} {...props} />
);

export const SlidersHIcon = (props) => (
  <FontAwesomeIcon icon="sliders-h" {...props} />
);

export const KeyIcon = (props) => <FontAwesomeIcon icon="key" {...props} />;

export const TrashIcon = (props) => <FontAwesomeIcon icon="trash" {...props} />;

export const SpinnerIcon = ({ spin, ...props }) => (
  <FontAwesomeIcon spin={spin} icon="spinner" {...props} />
);

export const PlusIcon = (props) => <FontAwesomeIcon icon="plus" {...props} />;

export const ChevronDownIcon = (props) => (
  <FontAwesomeIcon icon="chevron-down" {...props} />
);

export const DLXButtonIcon = ({ icon, color, ...props }) => (
  <FontAwesomeIcon icon={icon} color={color} {...props} />
);

export const SyncIcon = ({ spin, size }) => (
  <FAIcon size={size} spin={spin} icon="sync" />
);

export const SettingIcon = ({ size }) => <FAIcon size={size} icon={faCog} />;

export const ArchiveIcon = (props) => <FontAwesomeIcon icon="archive" {...props} />;

export const Source = ({ source, ...props }) => (
  <FontAwesomeIcon icon={source} {...props} />
);

export const CodeIcon = (props) => <FontAwesomeIcon icon="code" {...props} />;

export const QuestionIcon = (props) => (
  <FontAwesomeIcon {...props} icon="question-circle" />
);

export const ActorIcon = ({ actor, ...props }) => {
  switch (actor) {
    case "user-input":
      return <FontAwesomeIcon icon="user-alt" {...props} />;
    case "user-reading":
      return <FontAwesomeIcon icon="file-medical-alt" {...props} />;
    case "connection-command":
      return <FontAwesomeIcon icon="sign-in-alt" {...props} />;
    case "transform":
      return <FontAwesomeIcon {...props} icon="code" />;
    case "trigger-orchestration":
      return <FontAwesomeIcon {...props} icon="sign-out-alt" />;
    case "notification":
      return <FontAwesomeIcon {...props} icon="envelope" />;
    case "event-query":
      return <FontAwesomeIcon icon="search" {...props} />;
    default:
      return actor ? (
        <QuestionIcon {...props} />
      ) : (
        <FontAwesomeIcon {...props} icon="spinner" spin />
      );
  }
};

export const TriggerIcon = ({ trigger, ...props }) => {
  switch (trigger) {
    case "event":
      return <TriggerStepIcon {...props} />;
    case "timer":
      return <TimerTypeIcon {...props} />;
    case "manual":
      return <TouchAppRoundedIcon {...props} />;
    case "interactive":
      return <PlaylistAddCheckIcon {...props} />;
    case "orchestration":
      return <FontAwesomeIcon icon="sign-out-alt" {...props} />;
    default:
      return <QuestionIcon {...props} />;
  }
};
export const ActorChipIcon = ({ iconType, actors, ...props }) => {
  let icon = <FontAwesomeIcon {...props} icon="spinner" spin />;
  if (iconType) {
    icon = actors[iconType] ? (
      <FontAwesomeIcon icon={actors[iconType].icon} {...props} />
    ) : (
      <QuestionIcon {...props} />
    );
  }
  return icon;
};

export const ToggleShowOrchestrationListIcon = ({
  toggleShowOrchestrationListIcon,
  ...props
}) => <FontAwesomeIcon icon={toggleShowOrchestrationListIcon} {...props} />;

export const OrchestrationEnabled = (props) => (
  <FontAwesomeIcon icon="check-circle" {...props} />
);

export const OrchestrationDisabled = (props) => (
  <FontAwesomeIcon icon="times-circle" {...props} />
);

export const SearchThroughIcon = (props) => (
  <FontAwesomeIcon icon="search" {...props} />
);
export const HandPointUpIcon = () => <FontAwesomeIcon icon="hand-point-up" />;

export const CopyIcon = (props) => <FAIcon icon="copy" {...props} />;

export const EllipsisVIcon = (props) => (
  <FontAwesomeIcon icon="ellipsis-v" {...props} />
);

export const EllipsisHIcon = (props) => <FAIcon icon="ellipsis-h" {...props} />;

export const StopWatchIcon = (props) => (
  <FontAwesomeIcon icon="stopwatch" {...props} />
);

export const StopIcon = (props) => <FontAwesomeIcon icon="stop" {...props} />;

export const OrchestrationTriggerIcon = ({ orchestrationTriggerIcon, ...props }) => (
  <FontAwesomeIcon icon={orchestrationTriggerIcon} {...props} />
);

export const CheckIcon = (props) => <FontAwesomeIcon icon="check" {...props} />;

export const TimesIcon = (props) => <FontAwesomeIcon icon="times" {...props} />;

export const HeartbeatIcon = ({ color, animate, ...props }) => {
  const style = useMemo(
    () => ({
      color: color,
      animation: animate
        ? `heartbeat ${
            2 + crypto.getRandomValues(new Uint32Array(1))[0] / 0xffffffff
          }s infinite`
        : "none",
    }),
    [animate, color]
  );
  return <FontAwesomeIcon icon="heartbeat" {...props} style={style} />;
};

export const PlugIcon = ({ transform, ...props }) => (
  <FontAwesomeIcon icon="plug" {...props} transform={transform} />
);

export const ConnectionIcon = (props) => (
  <FontAwesomeIcon icon="plug" {...props} transform={{ rotate: 45 }} />
);

export const ReadingIcon = (props) => (
  <FontAwesomeIcon icon="file-medical-alt" {...props} />
);

export const CogsIcon = (props) => <FontAwesomeIcon icon="cogs" {...props} />;

export const ExclamationIcon = (props) => (
  <FontAwesomeIcon icon="exclamation" {...props} />
);

export const HeartBeatIcon = (props) => (
  <FontAwesomeIcon icon="heartbeat" {...props} />
);

export const UnlinkIcon = (props) => <FontAwesomeIcon icon="unlink" {...props} />;

export const ConnectorImageIcon = ({ connectorImageIcon, ...props }) => (
  <FontAwesomeIcon icon={connectorImageIcon} {...props} />
);

export const DLXChipLeftIcon = ({ leftIcon, ...props }) => (
  <FontAwesomeIcon icon={leftIcon} {...props} />
);

export const DLXChipRightIcon = ({ rightIcon, ...props }) => (
  <FontAwesomeIcon icon={rightIcon} {...props} />
);

export const ChipIcon = ({ iconType, spin, ...props }) => (
  <FontAwesomeIcon icon={iconType} {...props} spin={spin} />
);

export const ExclamationCircleIcon = (props) => (
  <FontAwesomeIcon icon="exclamation-circle" {...props} />
);

export const StepChipIcon = ({ stepChipIcon, ...props }) => (
  <FontAwesomeIcon icon={stepChipIcon} {...props} />
);

export const SourceIcon = ({ source, ...props }) => (
  <FontAwesomeIcon icon={source} {...props} />
);

export const PaperClipIcon = (props) => (
  <FontAwesomeIcon icon="paperclip" {...props} />
);

export const UserCircleIcon = (props) => (
  <FontAwesomeIcon icon="user-circle" {...props} />
);

export const ExecutionHistoryIcon = (props) => (
  <FontAwesomeIcon icon="history" {...props} />
);

export const EditOrchestrationIcon = (props) => (
  <FontAwesomeIcon icon="edit" {...props} />
);

export const TriggerOrchestrationIcon = (props) => (
  <FontAwesomeIcon icon="play" {...props} />
);

export const ItemStateIcon = ({ enabled, ...props }) => (
  <FontAwesomeIcon
    icon="circle"
    color={enabled ? colors.green : colors.lightgraytext}
    {...props}
  />
);

export const SourceDataSelectorIcon = ({ iconType, ...props }) => (
  <FontAwesomeIcon icon={iconType} {...props} />
);

export const AttributeIcon = ({ iconType, ...props }) => (
  <FontAwesomeIcon icon={iconType} {...props} />
);

export const UserLeaseStateIcon = ({ inControl, ...props }) => (
  <FontAwesomeIcon icon={inControl ? "hand-rock" : "eye"} {...props} />
);

export const SignInIcon = (props) => (
  <FontAwesomeIcon icon="sign-in-alt" {...props} />
);

export const TriggerTypeEventIcon = ({ iconType, ...props }) => (
  <FontAwesomeIcon icon={iconType} {...props} />
);

export const InfoCircleIcon = (props) => (
  <FontAwesomeIcon icon="info-circle" {...props} />
);

export const InfoIcon = (props) => <FontAwesomeIcon icon="info" {...props} />;

export const FlaskIcon = (props) => <FontAwesomeIcon icon="flask" {...props} />;

export const ShieldAltIcon = (props) => (
  <FontAwesomeIcon icon="shield-alt" {...props} />
);

export const TimesCircleIcon = (props) => (
  <FontAwesomeIcon icon="times-circle" {...props} />
);

export const BanIcon = (props) => <FontAwesomeIcon icon="ban" {...props} />;

export const PowerOffIcon = (props) => (
  <FontAwesomeIcon icon="power-off" {...props} />
);

export const CollapseUp = (props) => (
  <FontAwesomeIcon icon="chevron-up" {...props} />
);

export const ConnectionsIcon = ({ color, ...props }) => (
  <svg x="0px" y="0px" viewBox="5 0 90 90" {...props}>
    <g
      stroke="none"
      strokeWidth="1"
      fill={color ? color : colors.darkblue}
      fillRule="evenodd"
    >
      <path
        d="M36,66.32l-5.5,7.15C29.72,73.17,28.88,73,28,73c-3.86,0-7,3.14-7,7s3.14,
        7,7,7s7-3.14,7-7c0-1.53-0.5-2.94-1.34-4.09   l5.92-7.69C39.85,67.87,40,67.44,40,67V53h-4V66.32z
        M28,83c-1.66,0-3-1.35-3-3c0-1.66,1.34-3,3-3c1.65,0,3,1.34,3,3   C31,81.65,29.65,83,28,83z"
      ></path>
      <path
        d="M28,66V53h-4v11h-5.3c-0.86-2.89-3.54-5-6.7-5c-3.86,0-7,3.14-7,7s3.14,7,7,7c3.16,0,5.84-2.11,
      6.7-5H26   C27.1,68,28,67.1,28,66z M12,69c-1.66,0-3-1.35-3-3c0-1.66,1.34-3,3-3c1.65,0,3,1.34,3,3C15,
      67.65,13.65,69,12,69z"
      ></path>
      <path
        d="M52,79.29V53h-4v26.29c-2.89,0.87-5,3.54-5,6.71c0,3.86,3.14,7,7,7c3.85,0,7-3.14,
      7-7C57,82.83,54.88,80.16,52,79.29z    M50,89c-1.66,0-3-1.35-3-3c0-1.66,1.34-3,3-3c1.65,0,3,1.34,3,3C53,
      87.65,51.65,89,50,89z"
      ></path>
      <path
        d="M72,73c-0.89,0-1.73,0.17-2.5,0.47L64,66.32V53h-4v14c0,0.44,0.14,0.87,0.41,1.22l5.92,
      7.69C65.49,77.06,65,78.47,65,80   c0,3.86,3.14,7,7,7c3.85,0,7-3.14,7-7S75.85,73,72,73z M72,83c-1.66,
      0-3-1.35-3-3c0-1.66,1.34-3,3-3c1.65,0,3,1.34,3,3   C75,81.65,73.65,83,72,83z"
      ></path>
      <path
        d="M88,59c-3.17,0-5.85,2.11-6.71,5H76V53h-4v13c0,1.1,0.89,2,2,2h7.29c0.86,2.89,3.54,5,6.71,
      5c3.85,0,7-3.14,7-7   S91.85,59,88,59z M88,69c-1.66,0-3-1.35-3-3c0-1.66,1.34-3,3-3c1.65,0,3,1.34,3,
      3C91,67.65,89.65,69,88,69z"
      ></path>
      <path
        d="M18.31,49h63.37c5.14,0,9.32-4.26,9.32-9.5S86.82,30,81.68,30h-2.32c-0.55,
      0-1.07-0.23-1.45-0.62   c-0.38-0.4-0.58-0.94-0.55-1.48l0.03-0.4c0.01-0.22,0.03-0.43,0.03-0.65C77.42,
      15.9,68.67,7,57.92,7c-5.19,0-10.07,2.05-13.75,5.78   c-0.61,0.61-1.55,0.77-2.32,
      0.38c-2.08-1.04-4.29-1.56-6.57-1.56c-8.25,0-14.97,6.84-14.97,15.25c0,0.27,0.02,0.53,0.04,0.8   l0.01,
      0.22c0.04,0.55-0.15,1.09-0.53,1.5c-0.38,0.4-0.91,0.63-1.46,0.63C13.17,30,9,34.26,9,39.5S13.17,49,
      18.31,49z"
      ></path>
    </g>
  </svg>
);
export const OrchestrationIcon = ({ color, ...props }) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 215.66 215.36" {...props}>
    <path
      d="M102.18,215.37A107.83,107.83,0,0,1,47.12,18.57,107.23,107.23,0,0,1,102.18,0V47.29a60.66,60.66,0,0,0,0,120.79"
      transform="translate(0 -0.01)"
      style={{ fill: color ? color : "#a1ce5e" }}
    />
    <path
      d="M113.48,0A107.82,107.82,0,0,1,168.54,196.8a107.33,107.33,0,0,1-55.06,18.57V168.08a60.66,60.66,0,0,0,0-120.79"
      transform="translate(0 -0.01)"
      style={{ fill: color ? color : "#005587" }}
    />
    <path
      d="M102.18,215.37v0Z"
      transform="translate(0 -0.01)"
      style={{ fill: color ? color : "#a1ce5e" }}
    />
    {/*<path*/}
    {/*  d="M215.47,109.5s.62,53.72-46.93,87.13v18.72h47.12Z"*/}
    {/*  transform="translate(0 -0.01)"*/}
    {/*  style={{ fill: color ? color : "#005587" }}*/}
    {/*/>*/}
  </svg>
);

export const ScitaraLogo = ({ ...props }) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 432 144.25" {...props}>
    <path
      d="M396,384.49l6.44-12.75c6.31,4.16,16,7.11,25,7.11,10.61,0,15-3,15-7.92,0-13.68-44.28-.8-44.28-29.39,0-13.55,12.21-22.41,31.54-22.41,9.52,
      0,20.39,2.29,26.84,6.18l-6.45,12.75a39.16,39.16,0,0,0-20.53-5.37c-10.2,0-15,3.35-15,8.05,0,14.49,44.29,1.61,44.29,29.66,0,13.42-12.35,
      22.14-32.48,22.14-11.94,0-23.89-3.49-30.33-8.05"
      transform="translate(-396 -288)"
      style={{ fill: "#005587" }}
    />
    <path
      d="M462.14,355.77c0-21.47,16.1-36.64,38.78-36.64,14,0,25.1,5.77,30.6,16.64l-12.88,7.52a20.3,20.3,0,0,0-17.85-9.93c-12.35,0-21.74,8.59-21.74,
      22.41s9.39,22.41,21.74,22.41a20.3,20.3,0,0,0,17.85-9.93l12.88,7.51c-5.5,10.74-16.64,16.78-30.6,16.78-22.68,0-38.78-15.3-38.78-36.77"
      transform="translate(-396 -288)"
      style={{ fill: "#005587" }}
    />
    <path
      d="M540.76,319.94h16.77V391.6H540.76Zm-2.29-21.87c0-5.64,4.57-10.07,10.74-10.07S560,292.16,560,297.66c0,5.91-4.43,10.47-10.74,
      10.47s-10.74-4.43-10.74-10.06"
      transform="translate(-396 -288)"
      style={{ fill: "#005587" }}
    />
    <path
      d="M620.93,387.71c-4,3.22-10.06,4.83-16.1,4.83-15.7,0-24.7-8.32-24.7-24.16V333.9H568.32V320.48h11.81V304.1h16.78v16.38H616.1V333.9H596.91V368c0,
      7,3.49,10.74,9.93,10.74a15,15,0,0,0,9.39-3Z"
      transform="translate(-396 -288)"
      style={{ fill: "#005587" }}
    />
    <path
      d="M751.28,319.13v16a21,21,0,0,0-3.89-.4c-12.75,0-20.8,7.52-20.8,22.14V391.6H709.81V319.94h16v10.47c4.84-7.52,13.56-11.28,25.5-11.28"
      transform="translate(-396 -288)"
      style={{ fill: "#005587" }}
    />
    <path
      d="M657.9,391.55a37.27,37.27,0,0,1,0-74.43v16.34a21,21,0,0,0,0,41.75"
      transform="translate(-396 -288)"
      style={{ fill: "#a1ce5e" }}
    />
    <path
      d="M657.9,391.55v0Z"
      transform="translate(-396 -288)"
      style={{ fill: "#a1ce5e" }}
    />
    <path
      d="M661.75,333.46a21,21,0,0,1,0,41.75v16.34a36.94,36.94,0,0,0,19.08-6.42v6.47h16.29V354.34a37.28,37.28,0,0,0-35.37-37.22Z"
      transform="translate(-396 -288)"
      style={{ fill: "#005587" }}
    />
    <path
      d="M790.73,375.3a21,21,0,1,1,0-41.93h0a21,21,0,0,1,0,41.93Zm-21-51.76a37.27,37.27,0,0,0,21,68.06h0a37.1,37.1,0,0,0,
      21-6.47v6.47H828V354.34a37.26,37.26,0,0,0-37.26-37.27h0a37,37,0,0,0-21,6.47"
      transform="translate(-396 -288)"
      style={{ fill: "#005587" }}
    />
  </svg>
);

export const TriggerStepIcon = ({ ...props }) => (
  <svg
    width="19"
    height="26"
    viewBox="0 0 19 26"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <circle cx="5.7" cy="14.0599" r="4.7" stroke="currentColor" strokeWidth="2" />
    <circle cx="14.44" cy="4.56" r="3.56" stroke="currentColor" strokeWidth="2" />
    <circle cx="14.44" cy="21.28" r="2.8" stroke="currentColor" strokeWidth="2" />
    <line
      x1="9.1729"
      y1="9.93303"
      x2="12.2129"
      y2="6.89303"
      stroke="currentColor"
      strokeWidth="2"
    />
    <line
      x1="9.8271"
      y1="16.7729"
      x2="12.1071"
      y2="19.0529"
      stroke="currentColor"
      strokeWidth="2"
    />
  </svg>
);

export const OutputSourceIcon = ({ ...props }) => (
  <svg
    width="21"
    height="17"
    viewBox="0 0 21 17"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path d="M17 13L13.5359 7L20.4641 7L17 13Z" fill="currentColor" />
    <line
      x1="17"
      y1="1"
      x2="2"
      y2="0.999999"
      stroke="currentColor"
      strokeWidth="2"
    />
    <line
      x1="1"
      y1="4.37114e-08"
      x2="0.999999"
      y2="17"
      stroke="currentColor"
      strokeWidth="2"
    />
    <line x1="2" y1="16" x2="17" y2="16" stroke="currentColor" strokeWidth="2" />
    <line
      x1="17"
      y1="5"
      x2="17"
      y2="-4.37114e-08"
      stroke="currentColor"
      strokeWidth="2"
    />
    <line x1="5" y1="11" x2="11" y2="11" stroke="currentColor" strokeWidth="2" />
    <line x1="5" y1="6" x2="9" y2="6" stroke="currentColor" strokeWidth="2" />
  </svg>
);
