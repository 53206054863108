import React from "react";
import { uiTypeIs, rankWith } from "@jsonforms/core";
import { withJsonFormsCellProps, withJsonFormsControlProps } from "@jsonforms/react";
import MuiSelectorControl from "./MuiSelectorControl";

const OrchestrationSelectorTester = rankWith(
  103, //increase rank as needed
  uiTypeIs("DlxOrchestrationSelector")
);

const OrchestrationSelector = (props) => {
  props.uischema.options = {
    ...props.uischema.options,
    labelRequiresLookup: true,
    lookup: {
      route: "/lookups/orchestrations",
    },
  };
  if (props.uischema.options.nameContains) {
    props.uischema.options.lookup.options = {
      name: props.uischema.options.nameContains,
    };
  }
  return MuiSelectorControl(props);
};

export const OrchestrationSelectorControl = {
  renderer: withJsonFormsControlProps(React.memo(OrchestrationSelector)),
  tester: OrchestrationSelectorTester,
};

export const OrchestrationSelectorCell = {
  cell: withJsonFormsCellProps(React.memo(OrchestrationSelector)),
  tester: rankWith(1000, uiTypeIs("DlxOrchestrationSelector")),
};
