import React, { useEffect, useMemo, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import Button from "@mui/material/Button";
import ClickAwayListener from "@mui/material/ClickAwayListener";
import Grow from "@mui/material/Grow";
import MenuItem from "@mui/material/MenuItem";
import MenuList from "@mui/material/MenuList";
import Paper from "@mui/material/Paper";
import Popper from "@mui/material/Popper";
import styles from "./AccountDropdown.module.scss";
import { SortDownIcon } from "../../icons";
import clsx from "clsx";
import { useCurrentUserContext } from "../../contexts/current-user/Provider";
import { client } from "../../services/api/client";
import FeatureRequestIcon from "../../assets/icons/feature-request-icon.svg";
import PreferencesIcon from "../../assets/icons/preferences-icon.svg";
import SignoutIcon from "../../assets/icons/signout-icon.svg";
import ScitaraAcademy from "../../assets/icons/scitara-academy-icon.svg";
import KnowledgeBaseIcon from "../../assets/icons/knowledgebase-icon.svg";
import SupportIcon from "../../assets/icons/support-icon.svg";
import { Divider } from "@mui/material";
import { useScitaraHelp } from "@dlx/components/ScitaraHelp";
import { isInIframe } from "@dlx/utils/index";
import { useSessionContext } from "../../contexts/session/Provider";

const envEnums = {
  preprod: "Pre-Production",
  prod: "Production",
  test: "Testing",
};

const getTenant = (kind) => {
  return envEnums[kind] || "Development";
};

export default function AccountDropDown() {
  const links = [
    { id: "featureRequestUrl", title: "Feature request", icon: FeatureRequestIcon },
    { id: "onlineTrainingUrl", title: "Online training", icon: ScitaraAcademy },
    { id: "supportTicketUrl", title: "Support ticket", icon: SupportIcon },
  ];

  const SupportLink = ({ link }) => {
    // indirect: Whether href represents an API call to get the final URL or a URL to be opened directly.
    const { url, icon, title } = link;
    return (
      <a href={url} target="_blank" rel="noopener noreferrer">
        <div className={styles.menuItem}>
          <img src={icon} className={styles.icon} alt={title} />
          <p className={styles.title}>{title}</p>
        </div>
      </a>
    );
  };

  const [open, setOpen] = React.useState(false);
  const anchorRef = React.useRef(null);
  const loc = useLocation();
  const showPreferences = !loc.pathname.match("/invoke");
  const prevOpen = React.useRef(open);
  const { handleLogout } = useSessionContext();
  const { currentUser, customer } = useCurrentUserContext();
  const [helpLinks, setHelpLinks] = useState([]);

  useEffect(() => {
    let mounted = true;
    client.get("/help").then(({ data }) => {
      if (mounted) {
        setHelpLinks(
          links.map((l) => data[l.id] && { ...l, url: data[l.id] }).filter(Boolean)
        );
      }
    });
    return () => {
      mounted = false;
    };
  }, []);

  const handleToggle = () => {
    setOpen((o) => !o);
  };

  const handleClose = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }
    setOpen(false);
  };

  const handleListKeyDown = (event) => {
    if (event.key === "Tab") {
      event.preventDefault();
      setOpen(false);
    }
  };

  React.useEffect(() => {
    if (prevOpen.current === true && open === false) {
      anchorRef.current.focus();
    }
    prevOpen.current = open;
  }, [open]);

  const { isEnabled, openLink } = useScitaraHelp();
  const supportLinks = useMemo(() => {
    const items = helpLinks.map((link) => {
      return (
        <MenuItem key={link.id}>
          <SupportLink link={link} />
        </MenuItem>
      );
    });
    if (isEnabled) {
      items.push(
        <MenuItem key={"ko"}>
          <div className={styles.menuItem} onClick={openLink}>
            <img
              src={KnowledgeBaseIcon}
              className={styles.icon}
              alt={"Scitara Help Center"}
            />
            <p className={styles.title}>{"Scitara Help Center"}</p>
          </div>
        </MenuItem>
      );
    }
    return items;
  }, [helpLinks, isEnabled, openLink]);

  if (!currentUser) {
    return null;
  }

  if (isInIframe()) {
    return (
      <div className={styles.root}>
        <CustomerDetails customer={customer} user={currentUser} />
      </div>
    );
  }
  return (
    <div className={styles.root}>
      <Button
        ref={anchorRef}
        aria-controls={open ? "menu-list-grow" : undefined}
        aria-haspopup="true"
        onClick={handleToggle}
        className={styles.button}
        data-testid="dtid_account_dropdown_button"
      >
        <CustomerDetails user={currentUser} customer={customer} />
        <div className={styles.control}>
          <SortDownIcon />
        </div>
      </Button>
      <Popper
        open={open}
        anchorEl={anchorRef.current}
        role={undefined}
        transition
        placement="bottom-end"
        disablePortal
        className={styles.dropdown}
      >
        {({ TransitionProps, placement }) => (
          <Grow
            {...TransitionProps}
            style={{
              transformOrigin:
                placement === "bottom" ? "center top" : "center bottom",
            }}
          >
            <Paper>
              <ClickAwayListener onClickAway={handleClose}>
                <MenuList
                  autoFocusItem={open}
                  id="menu-list-grow"
                  className={styles.menuList}
                  onKeyDown={handleListKeyDown}
                >
                  {supportLinks}
                  {showPreferences && [
                    <Divider key={0} />,
                    <MenuItem key={1}>
                      <Link
                        to={{
                          pathname: "/user-preferences",
                          state: {
                            from: loc.pathname + loc.search,
                          },
                        }}
                      >
                        <div className={styles.menuItem}>
                          <img
                            src={PreferencesIcon}
                            className={styles.icon}
                            alt="preferences"
                          />
                          <p className={styles.title}>Preferences</p>
                        </div>
                      </Link>
                    </MenuItem>,
                  ]}
                  <MenuItem onClick={handleLogout} data-testid="dtid_logout">
                    <div className={styles.menuItem}>
                      <img
                        src={SignoutIcon}
                        className={styles.icon}
                        alt="Sign Off"
                      />
                      <p className={styles.title}>Sign Off</p>
                    </div>
                  </MenuItem>
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
    </div>
  );
}

export const CustomerDetails = ({ user, customer }) => {
  const isProduction = customer.kind === "Production";
  const tenant = getTenant(customer.kind);
  return (
    <div className={styles.customerDetails}>
      <span
        className={styles.userName}
        data-testid="dtid_account_dropdown_user_name"
      >
        {user.name}
      </span>
      <>
        {customer.name && (
          <span
            className={clsx(styles.tenantName, !isProduction && styles.defaultEnv)}
          >
            {customer.name}
            {customer.kind && !isProduction && (
              <span className={styles.tenantKind}> ({tenant})</span>
            )}
          </span>
        )}
      </>
    </div>
  );
};
