import React, { useCallback, useState, useEffect } from "react";
import { rankWith, or, uiTypeIs } from "@jsonforms/core";
import { withJsonFormsCellProps, withJsonFormsControlProps } from "@jsonforms/react";
import { Button } from "@mui/material";
import { useDispatch } from "react-redux";
import { alertError } from "../../../../state/alerts/actions";
import merge from "lodash/merge";
import DLXTooltip from "../../../DLXTooltip/DLXTooltip";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { get } from "lodash";
import { useGetOrchestration } from "../../../../state/hooks";
import DLXFrameModal from "../../../DLXFrameModal";
import useSocketEvent from "../../../../state/hooks/useSocketEvent";
import {
  ORCHESTRATION_EXECUTION_UPDATE,
  ORCHESTRATION_COMPLETED,
  ORCHESTRATION_ABORTED,
  ORCHESTRATION_FAILED,
  ORCHESTRATION_STEP_STARTED,
  ORCHESTRATION_INTERACTION_REQUESTED,
} from "../../../../state/executions/constants";
import { manualTrigger } from "../../../../state/orchestrations/orchestration.reducer";
import api from "../../../../services/api";
import { useJsonFormContext } from "../../JsonForm";

const COLLECTION_TOPICS = [
  ORCHESTRATION_COMPLETED,
  ORCHESTRATION_FAILED,
  ORCHESTRATION_ABORTED,
  ORCHESTRATION_STEP_STARTED,
  ORCHESTRATION_INTERACTION_REQUESTED,
];

const TriggerOrchestration = ({
  config,
  enabled,
  handleChange,
  label,
  path,
  uischema,
  visible,
}) => {
  const dispatch = useDispatch();
  const [working, setWorking] = useState(false);
  const [modalUrl, setModalUrl] = useState("");
  const [correlationId, setCorrelationId] = useState(null);
  const [executionId, setExecutionId] = useState(null);
  const { orchestration } = useGetOrchestration(uischema?.options?.orchestration);
  const appliedUiSchemaOptions = merge({}, config, uischema.options);
  const {
    color = "primary",
    size,
    triggerData,
    path: dataPath,
  } = appliedUiSchemaOptions;

  const { data } = useJsonFormContext();
  const rowPath = path.slice(0, path.lastIndexOf("."));
  const row = get(data, rowPath);

  const actionDisabled =
    !enabled ||
    working ||
    orchestration?.state !== "enabled" ||
    orchestration?.archived === true;

  const onClickHandler = useCallback(async () => {
    const validTriggerData =
      triggerData && typeof triggerData === "object" && !Array.isArray(triggerData);

    if (!triggerData || validTriggerData) {
      setWorking(true);
      const { correlationId: correlation } = await dispatch(
        manualTrigger(orchestration.id, { ...triggerData, row })
      );
      setExecutionId(null);
      setCorrelationId(correlation);
    } else {
      dispatch(alertError("triggerData must be an object"));
    }
  }, [dispatch, orchestration, triggerData, row]);

  useEffect(() => {
    if (executionId && uischema?.options?.showExecution) {
      const url = new URL(window.location.href);
      setModalUrl(`${url.protocol}//${url.host}/executions/${executionId}`);
    }
  }, [executionId, uischema]);

  useSocketEvent({
    skip: (message) =>
      (executionId
        ? message.executionId !== executionId
        : message.correlationId !== correlationId) ||
      !COLLECTION_TOPICS.includes(message.topic),
    event: ORCHESTRATION_EXECUTION_UPDATE,
    onCompleted: async (message) => {
      if (!executionId) {
        setExecutionId(message.executionId);
      }
      if (message.topic === ORCHESTRATION_COMPLETED) {
        setWorking(false);
        const orc = await api.orchestrations.get({
          id: uischema?.options?.orchestration,
        });
        const stepData = await api.executions.mappingSources({
          id: message.executionId,
          stepNumber: orc?.steps?.length || 0,
        });

        const targetPath = typeof row === "object" ? rowPath : path;
        const dataUpdate = dataPath ? get(stepData, dataPath) : stepData;
        if (dataUpdate) {
          handleChange(targetPath, dataUpdate);
        }
      }
      if (message.topic === ORCHESTRATION_FAILED) {
        setWorking(false);
        dispatch(alertError(`The orchestration failed`));
      }
      if (message.topic === ORCHESTRATION_ABORTED) {
        setWorking(false);
        dispatch(alertError(`The orchestration was aborted`));
      }
    },
  });

  if (!visible) {
    return null;
  }

  return (
    <>
      <DLXFrameModal
        open={!!modalUrl}
        onClose={() => setModalUrl("")}
        url={modalUrl}
        title="Execution view"
      />
      <DLXTooltip
        text={
          orchestration?.state !== "enabled"
            ? `${orchestration?.name || "Orchestration"} is not enabled`
            : ""
        }
        placement="bottom"
      >
        <Button
          color={color}
          size={size}
          disabled={actionDisabled}
          onClick={onClickHandler}
          variant="contained"
          style={{ margin: "5px" }}
          endIcon={
            <FontAwesomeIcon
              icon={working ? "spinner" : "dot-circle"}
              spin={working}
            />
          }
        >
          {label || uischema.label}
        </Button>
      </DLXTooltip>
    </>
  );
};

const TriggerOrchestrationTester = rankWith(
  110,
  or(uiTypeIs("DlxTriggerOrchestration"))
);

export const TriggerOrchestrationControl = {
  renderer: withJsonFormsControlProps(React.memo(TriggerOrchestration)),
  tester: TriggerOrchestrationTester,
};

export const TriggerOrchestrationCell = {
  cell: withJsonFormsCellProps(TriggerOrchestration),
  tester: rankWith(1000, uiTypeIs("DlxTriggerOrchestration")),
};
