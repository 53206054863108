import React, { useContext, useEffect, useMemo, useState } from "react";
import { useMountedState } from "react-use";
import { useApiClient } from "../api/Provider";
import { useSessionContext } from "../session/Provider";
import { LoadingIndicator } from "@dlx/components/LoadingScreen";
import { storeCurrentUser } from "@dlx/services/tokens";
import { useLocalStorage } from "react-use/lib";

export const CurrentUserContext = React.createContext(null);

export const useCurrentUserContext = () => useContext(CurrentUserContext);

export const usePermission = (action) => {
  const {
    currentUser: { permissions },
  } = useCurrentUserContext();
  if (action.length === 0) {
    return true;
  }
  if (Array.isArray(action)) {
    return action.some((p) => permissions.includes(p));
  }
  return permissions.includes(action);
};

export const useGetResourcePolicyGrants = (permission) => {
  const { currentUser } = useCurrentUserContext();
  const jsonObject = currentUser.resourcePolicyGrants;
  const parentKeysWithPermission = [];

  function searchKeys(obj) {
    if (typeof obj === "object" && obj !== null) {
      if (Array.isArray(obj)) {
        obj.forEach((item) => {
          if (
            Array.isArray(item.permissions) &&
            item.permissions.includes(permission)
          ) {
            if (item.labelSelector && item.labelSelector.value) {
              parentKeysWithPermission.push({
                name: item.labelSelector.name,
                value: item.labelSelector.value,
              });
            }
          }
          for (const key in item) {
            searchKeys(item[key]);
          }
        });
      } else {
        for (const key in obj) {
          searchKeys(obj[key]);
        }
      }
    }
  }

  searchKeys(jsonObject);

  return parentKeysWithPermission;
};

export const CurrentUserProvider = ({ children }) => {
  const { client } = useApiClient();
  const { iamToken } = useSessionContext();
  const [customer, setCustomer] = useState(null);
  const isMounted = useMountedState();
  const [currentUser, setCurrentUser] = useLocalStorage("currentUser");

  useEffect(() => {
    const fetchCurrentUser = async () => {
      if (iamToken) {
        const user = await storeCurrentUser(iamToken);
        if (isMounted()) {
          setCurrentUser(user);
        }
      }
    };
    fetchCurrentUser();
  }, [iamToken]);

  useEffect(() => {
    (async () => {
      if (currentUser) {
        const { data } = await client.get(`/customers/${currentUser.customer}`);
        if (isMounted()) {
          setCustomer(data);
        }
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [iamToken, currentUser]);

  const value = useMemo(
    () => ({
      currentUser,
      customer,
    }),
    [currentUser, customer, iamToken]
  );

  if (!customer) {
    return <LoadingIndicator />;
  }
  return (
    <CurrentUserContext.Provider value={value}>
      {children}
    </CurrentUserContext.Provider>
  );
};
