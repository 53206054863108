import React, { useCallback, useState } from "react";
import styles from "./ConnectionInterfaceTable.module.scss";
import { useSelector } from "react-redux";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import IconButton from "@mui/material/IconButton";
import { useDropzone } from "react-dropzone";
import DLXTooltip from "../../DLXTooltip/DLXTooltip";

const File = ({ recordImage = "optional", interfaceData, updateInterfaceData }) => {
  const [error, setError] = useState(false);

  const filestoreMaxFilesize = useSelector(
    (state) => state?.system?.constants?.entities?.filestoreMaxFilesize?.value
  );

  const onDropRejected = useCallback(() => {
    setError(`The maximum file size is ${filestoreMaxFilesize / 1000000}Mb`);
  }, [filestoreMaxFilesize]);

  const onDrop = useCallback(
    (acceptedFiles) => {
      acceptedFiles.forEach((file) => {
        const reader = new FileReader();
        reader.onerror = () => {
          return setError(`An error occurred reading ${file.path}`);
        };

        reader.onload = () => {
          setError("");
          if (file.size > filestoreMaxFilesize) {
            setError(`The maximum file size is ${filestoreMaxFilesize / 1000000}Mb`);
            return;
          }
          updateInterfaceData({
            filePackage: {
              filename: file.name,
              size: file.size,
              base64: reader.result,
            },
          });
        };
        reader.readAsDataURL(file);
      });
    },
    [updateInterfaceData, filestoreMaxFilesize]
  );

  const { getInputProps, open } = useDropzone({
    onDrop,
    onDropRejected,
    maxSize: filestoreMaxFilesize,
    multiple: false,
    noClick: true,
    noKeyboard: true,
  });

  if (recordImage === "none") {
    return null;
  }

  return (
    <tr>
      <td>Picture/file</td>
      <td>
        <div className={styles.rightCell}>
          <span>
            <input {...getInputProps()} />
            <span>
              {interfaceData.filePackage
                ? interfaceData.filePackage.filename
                : `File is ${recordImage}`}
            </span>
            {error && <div className={styles.error}>{`Error: ${error}`}</div>}
          </span>
          <div className={styles.buttonContainer}>
            <DLXTooltip text={"Add a file or picture"}>
              <IconButton size="small" variant="contained" onClick={open}>
                <FontAwesomeIcon icon="upload" />
              </IconButton>
            </DLXTooltip>
          </div>
        </div>
      </td>
    </tr>
  );
};

export default File;
