import React from "react";
import Badge from "@mui/material/Badge";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import Tooltip from "@mui/material/Tooltip";
import { createTheme } from "@mui/material/styles";
import { withStyles } from "tss-react/mui";

const theme = createTheme();

const styles = {
  badge: {
    color: theme.palette.error.main,
  },
};

const ValidationIcon = ({ classes, errorMessages, id }) => {
  const errorLength = errorMessages.split("\n").length;
  let errorTitle = errorMessages;
  if (errorLength > 1) {
    errorTitle = errorMessages
      .split("\n")
      .map((error, i) => <div key={i}>{`${i + 1}. ${error}`}</div>);
  }

  return (
    <Tooltip id={id} title={errorTitle}>
      <Badge classes={{ badge: classes.badge }} badgeContent={errorLength}>
        <ErrorOutlineIcon color="error" />
      </Badge>
    </Tooltip>
  );
};

export default withStyles(ValidationIcon, styles);
