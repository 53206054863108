import "@dlx/assets/styles";
import { getStoredIamToken, isTokenExpired } from "@dlx/services/tokens";
import { waitForSessionAuthenticated } from "@dlx/services/authChannel";
import React from "react";
import { createRoot } from "react-dom/client";
import RenderIFrame, { IFRAME_SCREEN } from "./components/IFrame/RenderIFrame";

const requiresSignIn = () => {
  const token = getStoredIamToken();
  return (
    !token || isTokenExpired(token.access_token) || isTokenExpired(token.id_token)
  );
};

(async () => {
  if (requiresSignIn()) {
    const appRoot = document.getElementById("app");

    appRoot &&
      createRoot(appRoot).render(<RenderIFrame screen={IFRAME_SCREEN.DEFAULT} />);
    await waitForSessionAuthenticated();
  }
  return require("./main");
})();
