import React, { useState, useEffect } from "react";
import styles from "./ConnectionInterfaceTable.module.scss";
import ActionButtons from "./ActionButtons";
import File from "./File";
import {
  TransientReading,
  MultipleReadings,
  SingleReading,
  UserReadings,
} from "./Readings";
import Memo from "./Memo";
import DLXModal from "../../DLXModal/DLXModal";
import useLeaseConnection from "../../../state/connections/lease/useLeaseConnection";
import useLiveReadings from "../../../state/connections/live-readings/useLiveReadings";
import { useDispatch } from "react-redux";
import { alertSuccess, alertWarning } from "../../../state/alerts/actions";
import { useGetFullConnection } from "../../../state/connections/useGetConnection";
import DLXTooltip from "../../DLXTooltip/DLXTooltip";
import { useCurrentUserContext } from "../../../contexts/current-user/Provider";
import { Attributes, Connection } from "./InterfaceModules";

function ConnectionInterfaceTable(props) {
  const { options = {}, setReadingInProgress, labels } = props;
  const [connectionId, setConnectionId] = useState(props.connectionId);
  const [waitingForReading, setWaitingForReading] = useState(false);
  const { currentUser } = useCurrentUserContext();
  const { clearReadings, permanentReadings } = useLiveReadings(connectionId);
  const { requestLease, abandonLease, queuedLeases } = useLeaseConnection();

  useEffect(() => {
    setWaitingForReading(false);
  }, [permanentReadings]);

  useEffect(() => {
    setConnectionId(props.connectionId);
  }, [props.connectionId]);

  const { fullConnection: connection } = useGetFullConnection(connectionId);
  const isOnline = connection?.status?.online?.valid;

  const multipleReadingsExpected =
    options.multi ||
    permanentReadings?.some((r) => Object.keys(r).includes("seriesComplete"));
  const waitingForSeries =
    permanentReadings?.some((r) => Object.keys(r).includes("seriesComplete")) &&
    permanentReadings.every((r) => !r.seriesComplete);

  const augmentedProps = {
    ...props,
    connection,
    isOnline,
    setWaitingForReading,
    waitingForReading,
    waitingForSeries,
  };
  useEffect(() => {
    if (isOnline && !queuedLeases[currentUser.id]) {
      requestLease(connectionId, options.attributes);
      return () => {
        abandonLease();
        clearReadings();
      };
    }
    return clearReadings;
    // eslint-disable-next-line
  }, [connectionId, isOnline]);

  useEffect(() => {
    if (multipleReadingsExpected && setReadingInProgress) {
      setReadingInProgress(waitingForSeries);
    }
  }, [waitingForSeries, setReadingInProgress, multipleReadingsExpected]);

  return (
    <table cellSpacing="0" cellPadding="0">
      <tbody className={styles.tableBody}>
        <Instructions instructions={options.instructions} />
        <Connection
          {...augmentedProps}
          setConnectionId={setConnectionId}
          labels={labels}
        />
        {isOnline && (
          <>
            <ControlStatus connection={connection} />
            <Actions {...augmentedProps} />
            <UserReadings {...augmentedProps} />
            <TransientReading connection={connection} />
            {multipleReadingsExpected ? (
              <MultipleReadings {...augmentedProps} />
            ) : (
              <SingleReading {...augmentedProps} />
            )}
            <Memo {...augmentedProps} recordMemo={options.recordMemo} />
            <File {...augmentedProps} recordImage={options.recordImage} />
            <Attributes attributes={options.attributes} />
          </>
        )}
      </tbody>
    </table>
  );
}

export default ConnectionInterfaceTable;

const ControlStatus = ({ connection }) => {
  const dispatch = useDispatch();
  const { currentUser } = useCurrentUserContext();
  const { controllingUser } = useLeaseConnection();

  const userIsInControl = controllingUser?.id === currentUser.id;

  useEffect(() => {
    if (controllingUser && userIsInControl) {
      dispatch(alertSuccess(`You are now in control of ${connection.name}`));
    } else if (controllingUser && !userIsInControl) {
      dispatch(alertWarning(`You are no longer in control of ${connection.name}`));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userIsInControl]);

  if (!controllingUser?.id) {
    return (
      <tr>
        <td>Control status</td>
        <td>{`Requesting control of ${connection.name}...`}</td>
      </tr>
    );
  }

  return (
    <tr className={userIsInControl ? styles.success : styles.error}>
      <td>Control status</td>
      <td>
        <div className={styles.controlStatus}>
          {userIsInControl ? (
            <>
              <span className={styles.name}>You</span>
              &nbsp;are currently in control of&nbsp;
              <span className={styles.name}>{connection.name}</span>
            </>
          ) : (
            <>
              <span className={styles.name}>{controllingUser.name}</span>
              &nbsp;is currently using&nbsp;
              <span className={styles.name}>{connection.name}</span>
              <div className={styles.controlComment}>
                You will be notified here when it becomes available
              </div>
            </>
          )}
        </div>
      </td>
    </tr>
  );
};

const Instructions = ({ instructions }) => {
  const [show, setShow] = useState(false);
  if (!instructions) {
    return null;
  }
  return (
    <tr>
      <td>Instructions</td>
      <td onClick={() => setShow(!show)}>
        <DLXTooltip text="Click to see more">
          <div className={styles.instructions}>
            {instructions}
            <DLXModal
              title="Instructions"
              open={show}
              size="micro"
              onCancel={() => setShow(!show)}
              cancelButtonLabel="Close"
            >
              <p>{instructions}</p>
            </DLXModal>
          </div>
        </DLXTooltip>
      </td>
    </tr>
  );
};

const Actions = ({
  waitingForReading,
  setWaitingForReading,
  waitingForSeries,
  connection,
  options: { attributes },
}) => {
  if (!Object.keys(connection?.connector?.actions || {}).length) {
    return null;
  }
  return (
    <tr>
      <td>Actions</td>
      <td>
        <ActionButtons
          waitingForReading={waitingForReading}
          waitingForSeries={waitingForSeries}
          setWaitingForReading={setWaitingForReading}
          connection={connection}
          allowReadings
          attributes={attributes}
        />
      </td>
    </tr>
  );
};
