import React, { useCallback, useEffect, useState } from "react";
import { TreeView, TreeItem } from "@mui/x-tree-view";
import FormControl from "@mui/material/FormControl";
import Popover from "@mui/material/Popover";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { makeStyles } from "tss-react/mui";
import { useJsonFormContext } from "../../JsonForm";
import ExpandMore from "@mui/icons-material/ExpandMore";
import ChevronRight from "@mui/icons-material/ChevronRight";
import _ from "lodash";

const useStyles = makeStyles()({
  root: {
    flexGrow: 1,
    minWidth: 400,
    padding: 10,
  },
  labelText: { fontSize: "14px", paddingTop: "3px", paddingBottom: "3px" },
  errorMessage: {
    fontSize: "14px",
  },
});

const textValue = (useLookupCache, data, lookupCache) => {
  if (!useLookupCache) {
    return Array.isArray(data) ? data.join("\r") : data;
  }
  return Array.isArray(data)
    ? data.map((d) => lookupCache[d]).join("\r")
    : lookupCache[data];
};

const setData = (value, data, uischema) => {
  if (uischema.options.multi) {
    if (Array.isArray(data) && data.includes(value)) {
      return data.filter((i) => i !== value);
    }
    return Array.isArray(data) ? [...data, value] : [value];
  }
  return value;
};

const useSelections = (uischema) => {
  const [expanded, setExpanded] = useState([]);
  const [errorMessage, setErrorMessage] = useState("");
  const [selections, setSelections] = useState(uischema?.options?.selections);
  const [selected, setSelected] = useState(null);
  const lookupConnection = uischema?.options?.lookup?.connection;
  const { executeLookupCommand, labels, useResourceFilter } = useJsonFormContext();
  const credentials = uischema?.options?.lookup?.credentials;
  const applyFilterBy = (items) => {
    const filterBy = uischema?.options?.filterBy || [];
    return items.filter((item) =>
      filterBy.every((filter) => {
        const itemPathValue = _.get(item, filter.path);
        return Array.isArray(filter.value)
          ? filter.value.includes(itemPathValue)
          : filter.value === itemPathValue;
      })
    );
  };

  useEffect(() => {
    if (uischema.options?.selections) {
      setSelections(uischema.options.selections);
    }
  }, [uischema.options?.selections]);

  const lookupSelection = async (item) => {
    item.populating = true;
    const sels = selections || uischema?.options?.selections;
    if (sels) {
      setSelections([...sels]);
    }
    if (item?.lookup) {
      try {
        const { result: items } = await executeLookupCommand(
          {
            ...item.lookup,
            connection: lookupConnection,
            credentials,
          },
          labels,
          useResourceFilter
        );
        item.populating = false;
        item.items = applyFilterBy(items || []);
        if (item.value) {
          setExpanded((e) => [...e, String(item.value)]);
        }
        setSelections([...(selections || item.items || [])]);
      } catch (error) {
        setErrorMessage(`Error retrieving options: ${error.message || error}`);
        return;
      }
    }
  };

  useEffect(() => {
    if (selected?.lookup && !selected.items) {
      lookupSelection(selected);
    }
    // eslint-disable-next-line
  }, [selected]);

  return {
    expanded,
    setExpanded,
    selections,
    selected,
    setSelected,
    setSelections,
    errorMessage,
  };
};

export default function MuiSelectorControl(props) {
  const { handleChange, label, path, uischema, errors, data, visible, enabled } =
    props;
  const { classes } = useStyles();
  const [anchorEl, setAnchorEl] = useState(null);
  const [rootLookup, setRootLookup] = useState(uischema.options?.lookup);
  const [renderedTree, setRenderedTree] = useState();
  const [lookupCache, setLookupCache] = useState({});
  const [selectedValue, setSelectedValue] = useState("");
  const {
    selections,
    expanded,
    setExpanded,
    setSelected,
    errorMessage,
    setSelections,
  } = useSelections(uischema);

  useEffect(() => {
    if (JSON.stringify(uischema.options?.lookup) !== JSON.stringify(rootLookup)) {
      delete uischema.options.items;
      setSelections(null);
      handleChange(path);
      setSelectedValue("");
      setRenderedTree(null);
      setRootLookup(uischema.options?.lookup);
    }
    // eslint-disable-next-line
  }, [uischema.options?.lookup]);

  useEffect(() => {
    const value = textValue(uischema.options.labelRequiresLookup, data, lookupCache);
    setSelectedValue(value);
  }, [data, lookupCache, uischema]);

  const renderTreeItem = useCallback(
    (item) => {
      if (typeof item === "string") {
        item = { label: item, value: item };
      }
      const icon =
        (item.populating && <FontAwesomeIcon spin icon={"spinner"} />) ||
        (item.lookup && !item.items && <ChevronRight onClick={handleLookup} />);
      return (
        <TreeItem
          key={item.value || item.label}
          nodeId={String(item.value)}
          label={
            <Typography
              onClick={() => handleSelect(item)}
              variant="body2"
              className={classes.labelText}
            >
              {item.label}
              {Array.isArray(data) && data.includes(item.value) && (
                <FontAwesomeIcon style={{ paddingLeft: "5px" }} icon="check" />
              )}
            </Typography>
          }
          icon={icon}
        >
          {item?.items && item.items.map((i) => renderTreeItem(i))}
        </TreeItem>
      );
    },
    // eslint-disable-next-line
    [data, expanded]
  );

  const handleLookup = (item) => {
    if (expanded.includes(String(item.value))) {
      setExpanded(expanded.filter((e) => e !== String(item.value)));
    } else {
      if (item.items) {
        setExpanded((e) => [...e, String(item.value)]);
      }
      setSelected(item);
    }
  };

  const handleSelect = (item) => {
    if ((item.lookup || item.items) && !item.selectable) {
      handleLookup(item);
      return;
    }

    setLookupCache({ ...lookupCache, [item.value]: item.path || item.label });
    setSelected(item);
    if (!uischema.options.multi && !item.lookup) {
      handleClose();
    }
    handleChange(path, setData(item.value, data, uischema));
  };

  useEffect(() => {
    if (Array.isArray(selections)) {
      setRenderedTree(selections.map((item) => renderTreeItem(item)));
    }
    // eslint-disable-next-line
  }, [selections, expanded, data]);

  const handleOpenSelectionsMenu = (event) => {
    if (!enabled) {
      return;
    }
    if (selections) {
      setSelected(selections);
    } else {
      setSelected(uischema.options);
    }
    setExpanded([]);
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);

  if (!visible) {
    return null;
  }

  return (
    <FormControl fullWidth>
      <TextField
        disabled={!enabled}
        error={Boolean(errorMessage) || Boolean(errors)}
        value={selectedValue || ""}
        helperText={errorMessage}
        onClick={handleOpenSelectionsMenu}
        fullWidth
        multiline
        label={label}
      />
      <Popover
        id="selector"
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
        style={{
          width: "100%",
        }}
      >
        <TreeView
          className={classes.root}
          expanded={expanded}
          defaultCollapseIcon={<ExpandMore />}
          defaultExpandIcon={<ChevronRight />}
        >
          {renderedTree}
          {!selections && (
            <Typography>
              <FontAwesomeIcon
                style={{ fontSize: "18px", marginRight: "10px" }}
                spin
                icon="spinner"
              />
              Retrieving selections
            </Typography>
          )}
          {selections?.length === 0 && (
            <Typography>No selections available</Typography>
          )}
        </TreeView>
      </Popover>
    </FormControl>
  );
}
