import React, { useCallback, useContext, useEffect, useState } from "react";
import { rankWith, or, uiTypeIs } from "@jsonforms/core";
import { withJsonFormsCellProps, withJsonFormsControlProps } from "@jsonforms/react";
import variables from "../../../../assets/styles/variables.scss";
import { makeStyles } from "tss-react/mui";
import Button from "@mui/material/Button";
import { useDropzone } from "react-dropzone";
import { useDispatch, useSelector } from "react-redux";
import { useStoreFile } from "../../../../state/filestores/filestores.reducer";
import DLXModal from "../../../DLXModal/DLXModal";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import styles from "./FileSelector.module.scss";
import { alertError, alertSuccess } from "../../../../state/alerts/actions";
import DLXChip from "../../../DLXChip/DLXChip";
import { FormControl } from "@mui/material";
import merge from "lodash/merge";
import { JsonFormContext } from "../../JsonForm";

const useStyles = makeStyles()(() => ({
  dialogContent: {
    padding: "25px",
  },
  dropZone: {
    borderStyle: "dashed",
    borderColor: variables.lightgrayborder,
    borderRadius: "5px",
    backgroundColor: variables.lightgraybackground,
    padding: "30px",
    textAlign: "center",
    fontSize: "16px",
    color: variables.lightgraytext,
  },
}));

const FileSelector = ({
  config,
  enabled,
  handleChange,
  label,
  path,
  uischema,
  visible,
}) => {
  const [showDialog, setShowDialog] = useState(false);
  const [uploadedFiles, setUploadedFiles] = useState([]);
  const { reset } = useContext(JsonFormContext);

  const handleDeleteItem = (item) => {
    setUploadedFiles(uploadedFiles.filter((f) => f.filename !== item.filename));
  };

  useEffect(() => {
    if (reset) {
      setUploadedFiles([]);
    }
  }, [reset]);

  const appliedUiSchemaOptions = merge({}, config, uischema.options);
  const { multi, accept } = appliedUiSchemaOptions;

  useEffect(() => {
    if (uploadedFiles.length) {
      handleChange(path, uploadedFiles);
    }
  }, [uploadedFiles, handleChange, path]);

  if (!visible) {
    return null;
  }

  return (
    <FormControl fullWidth={!appliedUiSchemaOptions.trim}>
      {showDialog && (
        <FileSelectorDialog
          onCancel={() => setShowDialog(false)}
          multi={multi}
          accept={accept}
          setUploadedFiles={setUploadedFiles}
          uploadedFiles={uploadedFiles}
        />
      )}
      <div className={styles.fileList} onClick={() => setShowDialog(enabled)}>
        {uploadedFiles.length
          ? uploadedFiles.map((file) => (
              <DLXChip
                key={file.filename}
                tooltip={`${file.filename} - ${(file.size / 1000).toFixed(1)} Kb `}
                leftIcon={
                  <FontAwesomeIcon icon="check" className={styles.fileOkIcon} />
                }
                rightIcon={
                  <FontAwesomeIcon
                    icon="times-circle"
                    className={styles.deleteIcon}
                  />
                }
                label={file.filename}
                onRightIconClick={() => handleDeleteItem(file)}
              />
            ))
          : label}
      </div>
    </FormControl>
  );
};

function FileSelectorDialog({
  multi = false,
  accept,
  setUploadedFiles,
  onCancel,
  uploadedFiles,
}) {
  const dispatch = useDispatch();
  const { classes } = useStyles();
  const [error, setError] = useState("");
  const [selectedFiles, setSelectedFiles] = useState([]);
  const [uploaded, setUploaded] = useState([...uploadedFiles]);
  const [working, setWorking] = useState(false);
  const filestoreMaxFilesize = useSelector(
    (state) => state?.system?.constants?.entities?.filestoreMaxFilesize?.value
  );

  const storeFile = useStoreFile();
  const filesUploadedSuccessfully =
    !!selectedFiles.length && selectedFiles.every((file) => file.uploaded);

  useEffect(() => {
    setUploadedFiles(uploaded);
  }, [filesUploadedSuccessfully]);

  const onUploadFiles = useCallback(async () => {
    setWorking(true);
    for (const file of selectedFiles) {
      const filePackage = await storeFile(file);
      if (filePackage.error) {
        setError(`An error occurred storing file: ${filePackage.error}`);
        setWorking(false);
        return;
      }
      setUploaded((files) => [...files, filePackage]);
      file.uploaded = true;
    }
    setSelectedFiles([...selectedFiles]);
    setWorking(false);
    dispatch(alertSuccess(`${selectedFiles.length} files uploaded successfully`));
  }, [dispatch, selectedFiles, setUploadedFiles, storeFile]);

  const onDropRejected = useCallback(
    (fileRejectionItems) => {
      fileRejectionItems.forEach(({ file, errors }) => {
        if (errors) {
          errors.forEach((e) => dispatch(alertError(`${file.name} : ${e.message}`)));
        }
      });
    },
    [dispatch]
  );

  const onDrop = useCallback(
    (acceptedFiles) => {
      acceptedFiles.forEach((file) => {
        if (!selectedFiles.find((f) => f.filename === file.name)) {
          const reader = new FileReader();
          const newFile = {
            filename: file.name,
            size: file.size,
            uploaded: false,
          };
          reader.onerror = () => {
            return setError(`An error occurred reading ${file.path}`);
          };

          reader.onload = () => {
            setError("");
            setSelectedFiles((files) =>
              multi
                ? files.concat({ ...newFile, base64: reader.result })
                : [{ ...newFile, base64: reader.result }]
            );
          };
          reader.readAsDataURL(file);
        }
      });
    },
    [selectedFiles, multi]
  );

  const { getRootProps, getInputProps, open } = useDropzone({
    onDrop,
    onDropRejected,
    accept,
    maxSize: filestoreMaxFilesize,
    multiple: multi,
    noClick: true,
    noKeyboard: true,
  });

  const handleDeleteItem = (item) => {
    setSelectedFiles(selectedFiles.filter((f) => f.filename !== item.filename));
  };

  return (
    <DLXModal
      open={true}
      title="Select files"
      size="small"
      onSubmit={
        filesUploadedSuccessfully
          ? () => onCancel()
          : () => {
              setWorking(true);
              onUploadFiles();
            }
      }
      submitDisabled={working || !selectedFiles.length}
      onCancel={onCancel}
      submitButtonLabel={filesUploadedSuccessfully ? "Ok" : "Upload selected files"}
      cancelButtonLabel="Cancel"
    >
      <div {...getRootProps({ className: classes.dropZone })}>
        <input {...getInputProps()} />
        <p>Drag and drop files here</p>
        <Button variant="contained" size="small" color="secondary" onClick={open}>
          Browse for file
        </Button>
        <p className={styles.errorText}>{error}</p>
      </div>

      {!!selectedFiles.length && (
        <div className={styles.fileList}>
          {selectedFiles.map((file) => (
            <DLXChip
              key={file.filename}
              tooltip={`${file.filename} - ${(file.size / 1000).toFixed(1)} Kb `}
              leftIcon={
                file.uploaded && (
                  <FontAwesomeIcon icon="check" className={styles.fileOkIcon} />
                )
              }
              rightIcon={
                <FontAwesomeIcon icon="times-circle" className={styles.deleteIcon} />
              }
              label={file.filename}
              onRightIconClick={() => handleDeleteItem(file)}
            />
          ))}
        </div>
      )}
    </DLXModal>
  );
}

const FileSelectorTester = rankWith(110, or(uiTypeIs("DlxFileSelector")));

export const FileSelectorControl = {
  renderer: withJsonFormsControlProps(React.memo(FileSelector)),
  tester: FileSelectorTester,
};

export const FileSelectorCell = {
  cell: withJsonFormsCellProps(React.memo(FileSelector)),
  tester: rankWith(1000, uiTypeIs("DlxFileSelector")),
};
