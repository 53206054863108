import React, {
  createContext,
  useState,
  useEffect,
  useContext,
  useMemo,
} from "react";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import Snackbar from "@mui/material/Snackbar";
import Alert from "@mui/lab/Alert";
import { useSessionContext } from "../session/Provider";
import { useDispatch } from "react-redux";
import { SOCKET_MESSAGE } from "@dlx/state/socket/socket.actions";
import { logger } from "@dlx/utils";
import { getStoredAccessToken, getStoredCurrentUser } from "@dlx/services/tokens";
import { LoadingIndicator } from "@dlx/components/LoadingScreen";

const log = logger("socket");

const SocketContext = createContext({
  status: "disconnected",
});

export const useSocketContext = () => useContext(SocketContext);

const severity = (status) => {
  return {
    ready: "success",
    disconnected: "error",
    connected: "warning",
  }[status];
};

const message = (status) => {
  return {
    ready: "Ready!",
    disconnected: "Disconnected",
    connected: "Connecting...",
  }[status];
};

/**
 * @param {{
 *   client: import("socket.io-client").Socket,
 *   children: import("react").ReactNode
 * }} props
 */
export const SocketProvider = ({ client, children }) => {
  const [status, setStatus] = useState("disconnected");
  const [isReady, setIsReady] = useState(false);
  const dispatch = useDispatch();
  const {
    isIdle,
    isAuthenticated,
    handleExpiredSession,
    handleExpiredToken,
    iamToken,
  } = useSessionContext();
  const [error, setError] = useState(null);

  useEffect(() => {
    client.onAny((event, data) => {
      dispatch({
        type: SOCKET_MESSAGE,
        payload: {
          event,
          data,
        },
      });
    });
  }, [client, dispatch]);

  useEffect(() => {
    client.on("connect", async () => {
      setError(null);
      log("connect");
      setStatus("connected");
      const token = getStoredAccessToken();
      client.emit("authenticate", { token });
    });
    client.on("ready", () => {
      log("ready");
      setStatus("ready");
      setIsReady(true);
      const user = getStoredCurrentUser();
      client.emit("register", {
        type: "user",
        connections: user.connections,
        email: user.email,
        id: user.sub,
      });
    });
    client.on("authenticated", () => {
      log("authenticated");
      setError(null);
    });
    client.on("unauthenticated", async (e) => {
      log("unauthenticated", e.name);
      setError(e);
      if (e.name === "TokenExpiredError") {
        await handleExpiredSession();
      }
      if (e.name === "AccessTokenExpiredError") {
        await handleExpiredToken();
      }
    });
    client.on("disconnect", async (e) => {
      log("disconnected");
      setStatus("disconnected");
      setError(e);
    });
    return () => {
      client.off();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (isAuthenticated && !isIdle) {
      client.connect();
    }
  }, [isAuthenticated, isIdle]);

  useEffect(() => {
    log("reauthenticating...");
    if (client.connected) {
      const token = getStoredAccessToken();
      client.emit("authenticate", { token });
    }
  }, [iamToken.access_token]);

  const value = useMemo(() => ({ status, error }), [status, error]);

  if (!isReady) {
    return <LoadingIndicator />;
  }
  return <SocketContext.Provider value={value}>{children}</SocketContext.Provider>;
};

export const SocketStatus = () => {
  const { status } = useSocketContext();
  const open = status !== "ready";
  return (
    <Backdrop open={open} style={{ zIndex: 1200 }}>
      <Snackbar
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
        style={{ zIndex: 100001 }}
        open={open}
      >
        <Alert elevation={6} variant="filled" severity={severity(status)}>
          {message(status)}
        </Alert>
      </Snackbar>
      <CircularProgress color="inherit" />
    </Backdrop>
  );
};
