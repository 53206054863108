import React from "react";
import MuiButton from "@mui/material/Button";
import Card from "@mui/material/Card";
import MuiCardActions from "@mui/material/CardActions";
import MuiCardHeader from "@mui/material/CardHeader";
import { withStyles } from "tss-react/mui";

const CardHeader = withStyles(MuiCardHeader, {
  root: {
    background: "#f6f6f6",
    borderBottom: "1px solid #dadce0",
    paddingBottom: 8,
    paddingTop: 8,
  },
  title: {
    color: "#6f6f6f",
    fontSize: 16,
  },
});

const CardActions = withStyles(MuiCardActions, {
  root: {
    justifyContent: "flex-end",
  },
});

const Button = withStyles(
  (props) => <MuiButton {...props} size="small" color="primary" />,
  {
    root: {
      fontSize: 14,
      textTransform: "capitalize",
    },
  }
);
/**
 *
 * @param {{
 *  children: import("react").ReactElement
 *  disabled: boolean,
 *  label: string
 *  onCancel: function,
 *  onSubmit: function,
 * }} props
 */
export const BaseControl = withStyles(
  (props) => {
    const { children, classes, label, onCancel, onSubmit, disabled } = props;
    return (
      <Card
        classes={{
          root: classes.root,
        }}
      >
        <CardHeader title={label} />
        <div style={{ maxHeight: "70vh", overflowY: "auto" }}>{children}</div>
        <CardActions>
          <Button
            style={{
              color: "#6f6f6f",
            }}
            onClick={onCancel}
          >
            Cancel
          </Button>
          <Button variant="contained" onClick={onSubmit} disabled={disabled}>
            Apply
          </Button>
        </CardActions>
      </Card>
    );
  },
  {
    root: {
      minWidth: 250,
    },
  }
);
